<template>
  <div class="relative inline-flex items-center justify-center rounded-full w-reg_16 h-reg_16" :class="color_class">
      <svg-icon :name="svg_name" class="absolute w-reg_10 h-reg_10 fill-reg_white" :class="[svg_name]" />
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
export default {
  name: "status-icon",
  components: {SvgIcon},
  props: ['color'],
  computed: {
      svg_name() {
        if(this.color === 'green')
          return "icon__check-2";
        else if(this.color === 'red')
          return "icon__close-2";
        else if(this.color === 'orange')
          return "icon__check-2";

        return "";
      },
      color_class() {
        if(this.color === 'green')
          return "bg-reg_green";
        else if(this.color === 'red')
          return "bg-reg_red";
        else if(this.color === 'orange')
          return "bg-reg_orange";

        return "";
      },
  }
}


</script>

<style scoped>

</style>