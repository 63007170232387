<template>
  <div class="block-btn_toggle default-btn flex flex-row items-center">
    <input type="checkbox"
         :id="id"
         :name="name"
         class="btn_toggle relative outline-none appearance-none left-0 flex w-reg_28 h-reg_16 bg-reg_white rounded-full cursor-pointer"
         :value="value"
         :class="className"
         :required="required"
         :disabled="disabled"
         @change="onChange"
         :checked="state">
    <label v-if="label" :for="id" class="text text-sm_reg text-reg_black pl-reg_8 cursor-pointer">
      {{label}}
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return 'checkbox-id-' + this._uid;
      },
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    modelValue: {
      default: undefined,
    },
    className: {
      type: String,
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    model: {},
    label : {
      type: String,
      default: "",
    }
  },
  computed: {
    state() {
      if (this.modelValue === undefined) {
        return this.checked;
      }
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.indexOf(this.value) > -1;
      }
      return !!this.modelValue;
    }
  },
  methods: {
    onChange() {
      this.toggle();
    },
    toggle() {
      let value;
      if (Array.isArray(this.modelValue)) {
        value = this.modelValue.slice(0);
        if (this.state) {
          value.splice(value.indexOf(this.value), 1);
        } else {
          value.push(this.value);
        }
      } else {
        value = !this.state;
      }
      this.$emit('input', this.name, value);
    }
  },
  watch: {
    checked(newValue) {
      if (newValue !== this.state) {
        this.toggle();
      }
    }
  },
  mounted() {
    if (this.checked && !this.state) {
      this.toggle();
    }
  },
};
</script>