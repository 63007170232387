<template>
  <div :class="[class_block ? class_block : '']">
    <label :class="[class_label ? class_label : '', required ? 'attention' : '']" v-if="label">
      {{label}}
    </label>

    <textarea :value="value" v-on="inputListeners" :class="[class_input, error ? 'bg-reg_pink_20 border-reg_red' : '']" :placeholder="placeholder"  />
    <p class="absolute text-reg_red text-xs_reg" v-if="error">{{error}}</p>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: 'UiTextArea',
  computed: {
    inputListeners() {
      const vm = this;
      return {
        ...vm.$listeners,
        input: (event) => vm.$emit('input', event.target.value),
        change: (event) => vm.$emit('change', event.target.value),
        keyup: () => vm.$emit('clearError', this.name),
      };
    },
  },
  props: {
    name : String,
    disabled: String,
    label: String,
    value: String,
    placeholder: String,
    error: String,
    description : String,
    class_input : String,
    class_block : String,
    class_label : String,
    required : Boolean,
  },
  methods: {

  }
});
</script>