import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";
import helperService from "@/common/services/helper.service";

const MENU_LEFT = "menu_left_id_";

const UserService = {
    login(credentials){
        return ApiService.post(API_OMS_URL+"/user/authorization", credentials);
    },
    getList(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/user/list", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    create(phone){
        return ApiService.post(API_OMS_URL+"/user/create", {phone : phone}, {
            headers: ApiService.getHeader(),
        });
    },
    getEvents(page){
        return ApiService.get(API_OMS_URL+"/user/events", {
            headers: ApiService.getHeader(),
            params : {page : page}
        });
    },
    deactivateEvent(eventId){
        return ApiService.put(API_OMS_URL+"/user/events/"+eventId, {},{
            headers: ApiService.getHeader(),
        });
    },
    deactivateEventAll(){
        return ApiService.put(API_OMS_URL+"/user/events/read/all", {},{
            headers: ApiService.getHeader(),
        });
    },
    searchUserByPhone(phone){
        return ApiService.get(API_OMS_URL+"/user/search-by-phone/" + phone, {
            headers: ApiService.getHeader()
        });
    },
    getInfo(){
        return ApiService.get(API_OMS_URL+"/user/info", {
            headers: ApiService.getHeader()
        });
    },
    getStatusTime(regionId){

        let params = {region_id : regionId};

        return  ApiService.get(API_OMS_URL+"/user/setting/status-time", {
            headers: ApiService.getHeader(),
            params : params
        });
    },
    updateStatusTime(regionId, values){
        return ApiService.put(API_OMS_URL+"/user/setting/status-time/"+regionId, {values : values},{
            headers: ApiService.getHeader(),
        });
    },
    getMenuLeft(){
        return Boolean(window.localStorage.getItem(MENU_LEFT));
    },
    setMenuLeft(status){
        if(!helperService.isEmpty(status))
            window.localStorage.setItem(MENU_LEFT, String(status));

    },
    getDepartmentEmployees(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/user/department/employees", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    }
}

export default UserService;