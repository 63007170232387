<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
export default {
  name: 'svgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
  },
  computed: {
    iconPath() {
      return require(`@/assets/markup/sprite.svg`) + "#" + this.name;
    },
  }
};
</script>