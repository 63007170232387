<script>
import AbstractPopup from "@/components/ui/abstractpopup";
import PopupService from "@/common/services/popup.service";

export default {
  name: "popup-rest",
  data () {
      return {
        title : "Остатки в других ТТ",
        items : [],
        name : "popup-rest"
      }
  },
  mixins : [AbstractPopup],
  props: {
      item_id : {
          type : Number,
          require : true
      }
  },
  watch: {
      $route: 'hidePopup',
  },
  methods : {
      async getItems(){
        this.beforeGetItems();
        await PopupService.getTable("product_rest", this.item_id, "", {})
            .then((response) => {
              this.setData(response.data);
            }).catch(() => {
              //todo
            })
            .finally(() => {
              this.afterGetItems();
            });
      },
      hidePopup(){
        this.$root.$emit("_popup_rest_hide");
      }
  }
}
</script>
