import OrderCreateService from "@/common/services/order.create.service";

import {
    GET_ORDER_CREATE_CONTACT,
    UPDATE_ORDER_CREATE_CONTACT_LOCAL,
    GET_ORDER_CREATE_DELIVERY,
    UPDATE_ORDER_CREATE_DELIVERY_LOCAL,
    UPDATE_ORDER_CREATE_COMMENT_LOCAL,
    SEARCH_USER_BY_PHONE,
    GET_ORDER_SELECTS,
    GET_CITIES,
    GET_LOCATIONS,
    GET_ORDER,
    UPDATE_ORDER_UPDATE_CONTACT,
    UPDATE_ORDER_CANCEL_CONTACT,
    UPDATE_ORDER_UPDATE_CONTACT_B2B,
    UPDATE_ORDER_CANCEL_CONTACT_B2B,
    UPDATE_ORDER_UPDATE_PRODUCTS,
    UPDATE_ORDER_CANCEL_PRODUCTS,
    UPDATE_ORDER_QUANTITY_PRODUCT,
    FILL_CONTACT,
    UPDATE_DB_ORDER_CREATE_CONTACT,
    UPDATE_DB_ORDER_CREATE_DELIVERY,
    UPDATE_DB_ORDER_CREATE_PAYMENT,
    ADD_PRODUCT, UPDATE_ORDER_CANCEL_DELIVERY,
    GET_ORDER_ADDITIONAL_INFO,
    UPDATE_ORDER_CREATE_PAYMENT_LOCAL,
    GET_ORDER_CREATE_PAYMENT,
    UPDATE_ORDER_CANCEL_PAYMENT,
    UPDATE_ORDER_CANCEL_COMMENT,
    GET_ORDER_CREATE_COMMENT,
    UPDATE_ORDER_UPDATE_COMMENT,
    UPDATE_ORDER_UPDATE_COMMENT_FOR_LOGIST,
    CLEAR_ORDER_LOCAL,
    UPDATE_DB_ORDER_CREATE_COMMENT,
    DELETE_COUPON
} from "./actions.type";

import {
    SET_ORDER_CREATE_CONTACT,
    SET_ORDER_CREATE_CONTACT_LOCAL,
    SET_USER_BY_PHONE,
    SET_ORDER_SELECTS,
    SET_CITIES,
    SET_LOCATIONS,
    SET_ORDER_CREATE_DELIVERY,
    SET_ORDER_CREATE_DELIVERY_LOCAL,
    UPDATE_ORDER,
    UPDATE_ORDER_CONTACT,
    UPDATE_ORDER_CONTACT_B2B,
    SET_CANCEL_ORDER_CONTACT,
    SET_CANCEL_ORDER_CONTACT_B2B,
    UPDATE_ORDER_PRODUCTS,
    SET_CANCEL_ORDER_PRODUCTS,
    SET_CONTACT_AFTER_FILL,
    SET_CANCEL_ORDER_DELIVERY,
    SET_ORDER_ADDITIONAL_INFO,
    SET_ORDER_CREATE_PAYMENT_LOCAL,
    SET_ORDER_CREATE_PAYMENT,
    SET_CANCEL_ORDER_PAYMENT,
    SET_CANCEL_ORDER_COMMENT,
    SET_ORDER_CREATE_COMMENT,
    SET_ORDER_CREATE_COMMENT_LOCAL,
    UPDATE_ORDER_COMMENT,
    UPDATE_ORDER_COMMENT_FOR_LOGIST,
    _DELETE_COUPON
} from "./mutations.type";

import HelperService from "@/common/services/helper.service";
import UserService from "@/common/services/user.service";
import OrderService from "@/common/services/order.service";
import helperService from "@/common/services/helper.service";

const state = {
    info : {
       payment_list : [],
       payment_id_default : null
    },
    order_create: {
        select : {
            location_other : null,
            location_default : null,
            locations : [],
            cities : [],
            intervals : []
        },
        contact : {
            name : null,
            last_name : null,
            second_name : null,
            phone : null,
            phone_contact : null,
            email : null,
            user_check : true,
            is_phone_contact : false,
            user_id : null,
            is_credit_retail : false,
            is_raketa : false,
            fast_collect : false,
            is_b2b : false,
            manager_phone: null,
        },
        delivery : {
            city : null,
            location : null,
            street : null,
            house : null,
            korpus : null,
            podezd : null,
            flat : null,
            floor : 1,
            is_cargo : false,
            is_rise_floor : true,
            type_delivery : 'delivery',
            interval : 9,
            interval_name : null,
            is_delivery_in_order_price : false
        },
        payment : {
            id : null
        },
        is_credit_payment : false,
        is_halva_payment : false,
        is_credit_here : false,
        is_online_payment : false,
        is_combine_payment_link : false,
        user : {},
        comment : null,
        comment_for_logist : null,
        discount : {},
        cancel_before : {
            is_active : false,
            text : "",
            can_deactivate : false
        },
        logistic_block : {
            znd : {
                allow : false,
                header : [],
                items : []
            },
            documents : {
                allow : false,
                header : [],
                items : []
            }
        },
        is_logistic_block : false,
        payment_external_block : {
            finance_21_vek : {
                allow : false,
                header : [],
                items : [],
                count_items : 0
            }
        },
        is_payment_external_block : false,
        can_send_to_collect_from_retail : false,
        properties : {
            collect_place : null
        },
        is_active_other_user_orders : false
    },
    order_update:{
        date_insert : null,
        contact : {
            name : null,
            last_name : null,
            second_name : null,
            phone : null,
            phone_contact : null,
            email : null,
            user_check : true,
            is_phone_contact : false,
            user_id : null,
            manager_phone: null,
        },
        delivery : {
            city : null,
            location : null,
            street : null,
            house : null,
            korpus : null,
            podezd : null,
            flat : null,
            floor : 1,
            is_cargo : false,
            is_rise_floor : true,
            type_delivery : 'delivery',
            interval: 9,
            interval_name : null,
            is_delivery_in_order_price : false
        },
        payment : {
            id : null
        },
        is_credit_payment : false,
        is_halva_payment : false,
        is_online_payment : false,
        is_paid : false,
        is_combine_payment_link : false,
        products : [],
        is_cancel : false,
        is_status_on_create : false,
        is_finish : false,
        is_b2b : false,
        info : {},
        status : {},
        lock_info : {
            is_lock : false,
            info : null
        },
        comment : null,
        comment_client : null,
        comment_for_logist : null,
        user : {},
        coupon : null,
        is_editable : false,
        is_editable_real : false,
        is_editable_status : false,
        labels : {
            order : [],
            order_readonly: [],
            user : []
        },
        hash : 0,
        is_send_shipments : false,
        cancel_before : {
            is_active : false,
            text : "",
            can_deactivate : false
        },
        is_products_deleted : false,
        products_deleted : [],
        notification : {
            contact : null,
            delivery : null,
            payment : null,
            delivery_info : null
        },
        payments_invoice : {
            list : [],
            count : 0
        },
        payments_invoice_data : {
            unp : null,
            full_name : null,
            address : null,
            bank_name : null,
            bank_address : null,
            bank_code : null
        },
        has_tile: false,
        logistic_block : {
            znd : {
                allow : false,
                header : [],
                items : []
            },
            documents : {
                allow : false,
                header : [],
                items : []
            }
        },
        is_logistic_block : false,
        is_credit_here : false,
        can_send_to_collect_from_retail : false,
        properties : {
            collect_place : null
        },
        payment_external_block : {
            finance_21_vek : {
                allow : false,
                header : [],
                items : [],
                count_items : 0
            }
        },
        is_payment_external_block : false,
        is_active_other_user_orders : false
    },
    order_current : {
        date_insert : null,
        contact : {},
        delivery : {},
        products : [],
        payment : {
            id : null
        },
        is_credit_payment : false,
        is_halva_payment : false,
        is_online_payment : false,
        is_paid : false,
        is_combine_payment_link : false,
        is_cancel : false,
        is_finish : false,
        comment : null,
        comment_for_logist : null,
        user : {
            cards : {
                has : false,
                current : null,
                sale : null,
                bonus : null
            }
        },
        card : {
            has : false,
            number : null,
            type : null,
            balance : 0,
            bonus_payment : 0
        },
        status : {},
        coupon : null,
        is_editable : false,
        payments_invoice_data : {
            unp : null,
            full_name : null,
            address : null,
            bank_name : null,
            bank_address : null,
            bank_code : null
        },
        is_credit_here : false,
        can_send_to_collect_from_retail : false,
        properties : {
            collect_place : null
        },
        is_active_other_user_orders : false
    }
};

const getters = {
    orderCreateContact(state) {
        return state.order_create.contact;
    },
    orderCreateDelivery(state) {
        return state.order_create.delivery;
    },
    orderCreatePayment(state) {
        return state.order_create.payment;
    },
    orderSelectLocationDefault(state) {
        return state.order_create.select.location_default;
    },
    orderSelectLocations(state) {
        return state.order_create.select.locations;
    },
    getSelectCities(state) {
        return state.order_create.select.cities;
    },
    isDeliveryEvropochtaUpdate(state) {
        return state.order_update.delivery.type_delivery === 'delivery_evropochta';
    },
    isDeliveryEvropochtaCreate(state) {
        return state.order_create.delivery.type_delivery === 'delivery_evropochta';
    },
    getSelectIntervals(state) {
        return state.order_create.select.intervals;
    },
    getSelectLocations(state) {
        return state.order_create.select.locations;
    },
    orderCityId(state) {
        return state.order_create.delivery.city.id;
    },
    orderLocationId(state) {

        if(HelperService.isEmpty(state.order_create.delivery.location) && !HelperService.isEmpty(state.order_create.delivery.city))
            return state.order_create.select.location_other;

        if(!HelperService.isEmpty(state.order_create.delivery.location))
            return state.order_create.delivery.location.id;

        return null;
    },
    orderUpdateLabelsOrder(){
        return state.order_update.labels['order'];
    },
    orderUpdateLabelsOrderReadOnly(){
        return state.order_update.labels['order_readonly'];
    },
    orderUpdateUserId(){
        return Number(state.order_update.contact.user_id);
    },
    orderUpdateLabelsUser(){
        return state.order_update.labels['user'];
    },
    orderUpdateContact(){
        return state.order_update.contact;
    },
    orderCurrentContact(){
        return state.order_current.contact;
    },
    orderUpdateDelivery(){
        return state.order_update.delivery;
    },
    orderUpdateDateInsert(){
        return state.order_update.date_insert;
    },
    orderCreateComment(state) {
        return state.order_create.comment;
    },
    orderUpdateComment(){
        return state.order_update.comment;
    },
    orderUpdateCommentForLogist(){
        return state.order_update.comment_for_logist;
    },
    orderCurrentComment(){
        return state.order_current.comment;
    },
    orderUpdateCommentClient(){
        return state.order_update.comment_client;
    },
    orderUpdatePayment(){
        return state.order_update.payment;
    },
    orderCurrentDelivery(){
        return state.order_current.delivery;
    },
    orderUpdateProducts(){
        return state.order_update.products;
    },
    orderCurrentProducts(){
        return state.order_current.products;
    },
    isOrderEditable(){
        return state.order_update.is_editable;
    },
    isOrderB2b(){
        return state.order_update.is_b2b;
    },
    isOrderHasTile(){
        return state.order_update.has_tile;
    },
    isOrderEditableReal(){
        return state.order_update.is_editable_real;
    },
    isOrderEditableStatus(){
        return state.order_update.is_editable_status;
    },
    isOrderCancel(){
        return state.order_update.is_cancel;
    },
    isOrderFinish(){
        return state.order_update.is_finish;
    },
    isOrderLocked(){
        return state.order_update.lock_info['is_lock'];
    },
    isOrderLockedInfo(){
        return state.order_update.lock_info['info'];
    },
    isOrderOnCreate(){
        return state.order_update.is_status_on_create;
    },
    orderInfo(){
        return state.order_update.info;
    },
    orderDelivery(){
        return state.order_update.delivery;
    },
    orderCoupon(){
        return state.order_update.coupon;
    },
    orderStatus(){
        return state.order_update.status;
    },
    orderPaymentList(){
        return state.info.payment_list;
    },
    orderCurrentPaymentId(){
        return state.order_current.payment.id;
    },
    isOrderCreditPayment(){
        return state.order_update.is_credit_payment;
    },
    isOrderHalvaPayment(){
        return state.order_update.is_halva_payment;
    },
    isOrderOnlinePayment(){
        return state.order_update.is_online_payment;
    },
    isLogisticBlock(){
        return state.order_update.is_logistic_block;
    },
    isPaymentExternalBlock(){
        return state.order_update.is_payment_external_block;
    },
    isOrderPaid(){
        return state.order_update.is_paid;
    },
    isOrderCombinePaymentLink(){
        return state.order_update.is_combine_payment_link;
    },
    orderUpdatePaymentId(){
        return state.order_update.payment.id;
    },
    paymentIdDefault(){
        return state.info.payment_id_default;
    },
    orderCurrentUserCards(){
        return state.order_current.user.cards;
    },
    orderCurrentCard(){
        return state.order_current.card;
    },
    orderHash(){
        return state.order_update.hash;
    },
    isSendShipments(){
        return state.order_update.is_send_shipments;
    },
    isCancelBefore(){
        return state.order_update.cancel_before.is_active;
    },
    textCancelBefore(){
        return state.order_update.cancel_before.text;
    },
    canDeactivateCancelBefore(){
        return state.order_update.cancel_before.can_deactivate;
    },
    isProductsDeleted(){
        return state.order_update.is_products_deleted;
    },
    productsDeleted(){
        return state.order_update.products_deleted;
    },
    notificationContact(){
        return state.order_update.notification.contact;
    },
    notificationDelivery(){
        return state.order_update.notification.delivery;
    },
    notificationPayment(){
        return state.order_update.notification.payment;
    },
    notificationDeliveryInfo(){
        return state.order_update.notification.delivery_info;
    },
    orderPaymentsInvoiceList() {
        return state.order_update.payments_invoice.list;
    },
    orderPaymentsInvoiceCount() {
        return state.order_update.payments_invoice.count;
    },
    orderPaymentsInvoiceData() {
        return state.order_update.payments_invoice_data;
    },
    getLogisticZnd() {
        return state.order_update.logistic_block.znd;
    },
    getLogisticDocuments() {
        return state.order_update.logistic_block.documents;
    },
    getPaymentFinance21Vek() {
        return state.order_update.payment_external_block.finance_21_vek;
    },
    isCreditHere(){
        return state.order_update.is_credit_here;
    },
    canSendToCollectFromRetail(){
        return state.order_update.can_send_to_collect_from_retail;
    },
    getCollectPlace(){
        return state.order_update.properties.collect_place;
    },
    isOtherUserOrders(){
        return state.order_update.is_active_other_user_orders;
    }
}

const actions = {
    async [GET_ORDER_CREATE_CONTACT](context) {
        let form = JSON.parse(OrderCreateService.getOrderContactLocal());
        context.commit(SET_ORDER_CREATE_CONTACT, form);
        return form;
    },
    async [GET_ORDER_CREATE_DELIVERY](context) {
        let form = JSON.parse(OrderCreateService.getOrderDeliveryLocal());
        context.commit(SET_ORDER_CREATE_DELIVERY, form);
        return form;
    },
    async [GET_ORDER_CREATE_COMMENT](context) {
        let comment = OrderCreateService.getOrderCommentLocal();
        context.commit(SET_ORDER_CREATE_COMMENT, comment);
        return comment;
    },
    async [GET_ORDER_CREATE_PAYMENT](context) {
        let form = JSON.parse(OrderCreateService.getOrderPaymentLocal());
        context.commit(SET_ORDER_CREATE_PAYMENT, form);
        return form;
    },
    async [UPDATE_ORDER_CREATE_CONTACT_LOCAL](context, data) {
        OrderCreateService.saveOrderContactLocal(JSON.stringify(data));
        context.commit(SET_ORDER_CREATE_CONTACT_LOCAL, data);
        return data;
    },
    async [UPDATE_ORDER_CREATE_DELIVERY_LOCAL](context, data) {
        OrderCreateService.saveOrderDeliveryLocal(JSON.stringify(data));
        context.commit(SET_ORDER_CREATE_DELIVERY_LOCAL, data);
        return data;
    },
    async [UPDATE_ORDER_CREATE_COMMENT_LOCAL](context, data) {
        OrderCreateService.saveOrderCommentLocal(String(data));
        context.commit(SET_ORDER_CREATE_COMMENT_LOCAL, data);
        return data;
    },
    async [UPDATE_ORDER_CREATE_PAYMENT_LOCAL](context, data) {
        OrderCreateService.saveOrderPaymentLocal(JSON.stringify(data));
        context.commit(SET_ORDER_CREATE_PAYMENT_LOCAL, data);
        return data;
    },
    async [SEARCH_USER_BY_PHONE](context, phone) {
        let {data} = await UserService.searchUserByPhone(phone);
        context.commit(SET_USER_BY_PHONE, data.message);
        return data.message;
    },
    async [GET_ORDER_SELECTS](context) {
        let {data} = await OrderService.getSelectValues();
        context.commit(SET_ORDER_SELECTS, data.message);
        return data.message;
    },
    async [GET_CITIES](context, search) {

        let {data} = await OrderService.searchCity(search);
        context.commit(SET_CITIES, data.message);
        return data.message;
    },
    async [GET_LOCATIONS](context, cityId) {

        if(!helperService.isEmpty(cityId)){
            let {data} = await OrderService.searchLocation(cityId);
            context.commit(SET_LOCATIONS, data.message);
            return data.message;
        }

        return [];
    },
    async [GET_ORDER](context, orderId) {

        if(!helperService.isEmpty(orderId)){
            let {data} = await OrderService.getOrder(orderId);
            context.commit(UPDATE_ORDER, data.message);
            return data.message;
        }

        return {};
    },

    async [UPDATE_ORDER_UPDATE_CONTACT](context, data) {
        context.commit(UPDATE_ORDER_CONTACT, data);
        return data;
    },
    async [UPDATE_ORDER_UPDATE_CONTACT_B2B](context, data) {
        context.commit(UPDATE_ORDER_CONTACT_B2B, data);
        return data;
    },
    async [UPDATE_ORDER_UPDATE_COMMENT](context, data) {
        context.commit(UPDATE_ORDER_COMMENT, data);
        return data;
    },
    async [UPDATE_ORDER_UPDATE_COMMENT_FOR_LOGIST](context, data) {
        context.commit(UPDATE_ORDER_COMMENT_FOR_LOGIST, data);
        return data;
    },
    async [UPDATE_ORDER_CANCEL_CONTACT](context) {
        context.commit(SET_CANCEL_ORDER_CONTACT);
    },
    async [UPDATE_ORDER_CANCEL_CONTACT_B2B](context) {
        context.commit(SET_CANCEL_ORDER_CONTACT_B2B);
    },
    async [UPDATE_ORDER_CANCEL_DELIVERY](context) {
        context.commit(SET_CANCEL_ORDER_DELIVERY);
    },
    async [UPDATE_ORDER_CANCEL_PAYMENT](context) {
        context.commit(SET_CANCEL_ORDER_PAYMENT);
    },
    async [UPDATE_ORDER_CANCEL_COMMENT](context) {
        context.commit(SET_CANCEL_ORDER_COMMENT);
    },
    async [UPDATE_ORDER_UPDATE_PRODUCTS](context, data) {
        context.commit(UPDATE_ORDER_PRODUCTS, data);
        return data;
    },

    async [UPDATE_ORDER_CANCEL_PRODUCTS](context) {
        context.commit(SET_CANCEL_ORDER_PRODUCTS);
    },
    async [UPDATE_ORDER_QUANTITY_PRODUCT](context, payload) {
        let {data} = await OrderService.updateQuantityProduct(payload);
        return data;
    },
    async [FILL_CONTACT](context, payload) {
        let {data} = await OrderService.filContactUser(payload['phone']);
        context.commit(SET_CONTACT_AFTER_FILL, {user_data : data.message.user, order_id : payload['order_id']});
        return data.message.user;
    },
    async [UPDATE_DB_ORDER_CREATE_CONTACT](context, payload) {
        let {data} = await OrderService.updateContactOrder(payload['order_id'], payload['contact']);
        return data.message;
    },
    async [UPDATE_DB_ORDER_CREATE_COMMENT](context, payload) {
        let {data} = await OrderService.updateCommentOrder(payload['order_id'], {comment : payload['comment'], comment_for_logist : payload['comment_for_logist']});
        return data.message;
    },
    async [UPDATE_DB_ORDER_CREATE_DELIVERY](context, payload) {
        let {data} = await OrderService.updateDeliveryOrder(payload['order_id'], payload['delivery']);
        return data;
    },
    async [UPDATE_DB_ORDER_CREATE_PAYMENT](context, payload) {
        let {data} = await OrderService.updatePaymentOrder(payload['order_id'], payload['payment_id']);
        return data.message;
    },
    async [ADD_PRODUCT](context, payload) {
        let {data} = await OrderService.addProduct(payload['order_id'], payload['product_id'], payload['quantity']);
        return data;
    },
    async [GET_ORDER_ADDITIONAL_INFO](context) {
        let {data} = await OrderService.additionInfo();
        context.commit(SET_ORDER_ADDITIONAL_INFO, data.message);
        return data.message;
    },
    async [CLEAR_ORDER_LOCAL]() {
        await OrderCreateService.destroyOrderContactLocal();
        await OrderCreateService.destroyOrderDeliveryLocal();
        await OrderCreateService.destroyOrderPaymentLocal();
    },
    [DELETE_COUPON](context) {
        context.commit(_DELETE_COUPON);
    },
};

const mutations = {
    [_DELETE_COUPON](state) {
        state.order_update.coupon = null;
    },
    [SET_ORDER_CREATE_CONTACT](state, data) {
        if(!HelperService.isEmpty(data)){
            for (let key in data) {
                if(!HelperService.isEmpty(data[key]))
                    state.order_create.contact[key] = data[key];
            }
        }
    },
    [SET_ORDER_CREATE_DELIVERY](state, data) {
        if(!HelperService.isEmpty(data)){
            for (let key in data) {
                if(!HelperService.isEmpty(data[key]))
                    state.order_create.delivery[key] = data[key];
            }
        }
    },
    [SET_ORDER_CREATE_COMMENT](state, data) {
        if(!HelperService.isEmpty(data)){
            state.order_create.comment = data;
        }
    },
    [SET_ORDER_CREATE_CONTACT_LOCAL](state, data) {
        OrderCreateService.saveOrderContactLocal(data);
    },
    [SET_ORDER_CREATE_DELIVERY_LOCAL](state, data) {
        OrderCreateService.saveOrderDeliveryLocal(data);
    },
    [SET_ORDER_CREATE_COMMENT_LOCAL](state, data) {
        OrderCreateService.saveOrderCommentLocal(data);
        state.order_create.comment = data;
    },
    [SET_ORDER_CREATE_PAYMENT](state, data) {
        if(!HelperService.isEmpty(data)){
            for (let key in data) {
                if(!HelperService.isEmpty(data[key]))
                    state.order_create.payment[key] = data[key];
            }
        }
        if(HelperService.isEmpty(state.order_create.payment['id']))
            state.order_create.payment.id = state.info.payment_id_default;

    },
    [SET_ORDER_CREATE_PAYMENT_LOCAL](state, data) {
        OrderCreateService.saveOrderPaymentLocal(data);
    },
    [SET_USER_BY_PHONE](state, data) {
        state.order_create.contact.user_id = data['user']['id'];
        state.order_create.user = data;
    },
    [SET_ORDER_SELECTS](state, data) {
        state.order_create.select.locations = data['locations'];
        state.order_create.select.location_default = data['location_default'];
    },
    [SET_CITIES](state, data) {
        state.order_create.select.cities = data['cities'];
        state.order_create.select.intervals = data['intervals'];
    },
    [SET_LOCATIONS](state, data) {
        state.order_create.select.location_other = data['location_other'];
        state.order_create.select.locations = data['locations'];
    },
    [UPDATE_ORDER](state, data) {
        HelperService.updateObject(state.order_update.contact, data['contact']);
        HelperService.updateObject(state.order_current.contact, data['contact']);

        HelperService.updateObject(state.order_update.delivery, data['delivery']);
        HelperService.updateObject(state.order_current.delivery, data['delivery']);

        state.order_update.products = data['products'];
        //HelperService.updateObject(state.order_update.products, data['products']);
        HelperService.updateObject(state.order_current.products, data['products']);

        state.order_update.payment.id = data['pay_system_id'];
        state.order_current.payment.id = data['pay_system_id'];

        state.order_update.is_cancel = data['is_cancel'];
        state.order_update.is_finish = data['is_finish'];
        state.order_update.is_status_on_create = data['is_status_on_create'];
        state.order_update.info = data['info'];
        state.order_update.status = data['status'];
        state.order_update.lock_info = data['lock_info'];
        state.order_update.is_editable = data['is_editable'];
        state.order_update.is_editable_real = data['is_editable_real'];
        state.order_update.is_editable_status = data['is_editable_status'];

        state.order_update.is_send_shipments = data['is_send_shipments'];

        state.order_update.cancel_before.is_active = data['cancel_before']['is'];
        state.order_update.cancel_before.text = data['cancel_before']['text'];
        state.order_update.cancel_before.can_deactivate = data['cancel_before']['can_deactivate'];

        state.order_update.is_credit_payment = data['is_credit_payment'];
        state.order_update.is_halva_payment = data['is_halva_payment'];

        state.order_update.is_online_payment = data['is_online_payment'];
        state.order_update.is_paid = data['is_paid'];

        state.order_update.is_combine_payment_link = data['is_combine_payment_link'];

        state.order_update.hash = data['hash'];

        state.order_update.is_logistic_block = data['is_logistic_block'];

        state.order_current.comment = data['comment'];
        state.order_update.comment = data['comment'];

        state.order_current.comment_for_logist = data['comment_for_logist'];
        state.order_update.comment_for_logist = data['comment_for_logist'];
        
        state.order_update.comment_client = data['comment_client'];

        //discount
        state.order_current.user.cards = data['user']['cards'];
        state.order_current.card = data['card'];

        state.order_update.coupon = data['coupon'];
        state.order_update.date_insert = data['date_insert'];

        state.order_update.labels = data['labels'];
        state.order_update.is_products_deleted = data['is_products_deleted'];
        state.order_update.products_deleted = data['products_deleted'];

        state.order_update.notification.contact = data['notification']['contact'];
        state.order_update.notification.delivery = data['notification']['delivery'];
        state.order_update.notification.payment = data['notification']['payment'];
        state.order_update.notification.delivery_info = data['notification']['delivery_info'];

        state.order_update.payments_invoice.list = data['payments_invoice']['list'];
        state.order_update.payments_invoice.count = data['payments_invoice']['count'];

        if(!HelperService.isEmpty(data['payments_invoice']['field_values'])){
            state.order_update.payments_invoice_data = {...data['payments_invoice']['field_values']};
            state.order_current.payments_invoice_data = {...data['payments_invoice']['field_values']};
        }

        state.order_update.is_b2b = data['is_b2b'];
        state.order_update.has_tile = data['has_tile'];

        state.order_update.logistic_block.znd = data['logistic_block']['znd'];
        state.order_update.logistic_block.documents = data['logistic_block']['documents'];

        state.order_update.is_credit_here = data['is_credit_here'];
        state.order_update.is_delivery_in_order_price = data['is_delivery_in_order_price'];
        state.order_update.can_send_to_collect_from_retail = data['can_send_to_collect_from_retail'];

        state.order_update.properties = data['properties'];


        state.order_update.is_payment_external_block = data['is_payment_external_block'];

        state.order_update.payment_external_block.finance_21_vek = data['payment_external_block']['finance_21_vek'];

        state.order_update.is_active_other_user_orders = data['is_active_other_user_orders'];


    },
    [UPDATE_ORDER_CONTACT](state, data) {
        HelperService.updateObject(state.order_update.contact, data);
    },
    [UPDATE_ORDER_CONTACT_B2B](state, data) {
        HelperService.updateObject(state.order_update.payments_invoice_data, data);
    },
    [UPDATE_ORDER_COMMENT](state, data) {
        state.order_update.comment = data.comment;
    },
    [UPDATE_ORDER_COMMENT_FOR_LOGIST](state, data) {
        state.order_update.comment_for_logist = data.comment_for_logist;
    },
    [SET_CANCEL_ORDER_CONTACT](state) {
        HelperService.updateObject(state.order_update.contact, state.order_current.contact);
    },
    [SET_CANCEL_ORDER_CONTACT_B2B](state) {
        HelperService.updateObject(state.order_update.payments_invoice_data, state.order_current.payments_invoice_data);
    },
    [SET_CANCEL_ORDER_DELIVERY](state) {
        HelperService.updateObject(state.order_update.delivery, state.order_current.delivery);
    },
    [SET_CANCEL_ORDER_PAYMENT](state) {
        HelperService.updateObject(state.order_update.payment, state.order_current.payment);
    },
    [SET_CANCEL_ORDER_COMMENT](state) {
        state.order_update.comment = state.order_current['comment'];
        state.order_update.comment_for_logist = state.order_current['comment_for_logist'];
    },
    [UPDATE_ORDER_PRODUCTS](state, data) {
        state.order_update.products = data;
        //HelperService.updateObject(state.order_update.products, data);
    },
    [SET_CANCEL_ORDER_PRODUCTS](state) {
        HelperService.updateObject(state.order_update.products, state.order_current.products);
    },
    [SET_CONTACT_AFTER_FILL](state, data) {

        let fields = ['name', 'second_name', 'last_name', 'email'];

        for (let key in fields){
            if(HelperService.isEmpty(data['order_id']))
                state.order_create.contact[fields[key]] = data['user_data'][fields[key]];
            else
                state.order_update.contact[fields[key]] = data['user_data'][fields[key]];
        }
    },
    [SET_ORDER_ADDITIONAL_INFO](state, data) {
        state.info.payment_list = data['payments'];
        state.info.payment_id_default = data['payment_id_default'];
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};