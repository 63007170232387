<template>
  <div>
    <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"></div>
    <div class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center">
      <div class="modal-block rounded bg-reg_white p-reg_24 w-reg_1000" :class="width ? width : 'w-reg_1000'">


        <div class="modal-block_header relative">
          <h2 class="modal-block_title text-lg_l_reg text-reg_black font-semibold" v-if="title">{{title}}</h2>

          <div class="info" v-if="info && loaded_table">

          </div>


          <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mt-reg_10_m mr-reg_10_m" type="button" @click="hidePopup">
            <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75" />
          </button>


        </div>

        <div class="modal-block_body">

          <div class="h-reg_320 overflow-hidden"  style="max-height: 65vh; overflow-y: auto; margin-top: 20px;">

              <v-table
                  :with_padding_x="false"
                  class_not_found = ""
                  class_block="popup_block"
                  :is_xlsx="is_xlsx"
                  :table="table"
                  :items="items"
                  :loaded="loaded_table"
                  :loaded_filter="true"
                  :info="info"
                  :pagination_data="{}"
                  @xlsx="xlsx"
              />
          </div>
        </div>


      </div>
    </div>

  </div>

</template>

<script>
import VTable from "@/components/ui/table";
import SvgIcon from "@/components/ui/svgicon";

export default {
  name: "abstractPopup",
  components: {VTable, SvgIcon},
  data: () => ({
      title : "",
      name : "",
      table : [],
      width : 1000,
      items : [],
      info : [],
      loaded_table : false,
      allow : true,
  }),
  props: {
    is_xlsx : {
      type :Boolean,
      default : false,
    },
  },
  created(){
      this.$nextTick(() => {
          this.getItems();
      });
  },
  methods: {
    getItems(){
      // abstract
    },
    xlsx(){
      // abstract
    },
    loaderFinish(){
      setTimeout(() => {
        this.loaded_table = true;
      }, 150);
    },
    setItems(items){
      this.items = items;
    },
    setTable(table){
        this.table = table;
    },
    setInfo(info){
      this.info = info;
    },
    setData(data){
        this.setTable(data.table);
        this.setItems(data.items);
        this.setInfo(data.info);
    },
    beforeGetItems(){
        this.loaded_table = false;
    },
    afterGetItems(){
      this.loaderFinish();
    },
    hidePopup(){
        // abstract
    },
  }
}
</script>

<style scoped>

</style>