<template>
    <div class="flex flex-row justify-between items-center collect-tab">
      <ul class="px-reg_24 pb-reg_24" v-if="is_load">
        <li class="inline-flex text-xs_l_reg text-reg_gray_60" v-for="(tab) in list" v-bind:key="tab['route']" >
          <router-link :to="{ name: tab['route']}" :title="tab['title']" class="tab_label inline-flex text-sm_reg  rounded border py-reg_5 mb-reg_4  px-reg_10 mr-2 cursor-pointer hover:text-reg_white hover:bg-reg_blue"  :class="[routeName === tab['route'] ? 'router-link-active text-reg_white bg-reg_blue' : 'bg-reg_white border-reg_gray_75']">
            <span v-if="tab['is_count_view']" class="inline-flex flex-row items-center justify-center text-xs_reg text-reg_white w-reg_24 h-reg_24 rounded-full" :class="[tab['count'] > 0 ? 'bg-reg_red' : 'bg-reg_green']">{{ tab['count'] }}</span>
            <span class="text text-sm_reg  text-xs_l_reg ml-reg_8">{{ tab['title'] }}</span>
          </router-link>
        </li>
      </ul>
    </div>
</template>

<script>
import CollectService from "@/common/services/collect.service";
import HelperService from "@/common/services/helper.service";

export default {
  name: "collect-tab",
  data: () => ({
    is_load : false,
    list : [
        {'route' : 'order_collect_actual', 'title' : 'Актуальные задачи ИМ', 'count': 0, 'is_count_view' : true},
        {'route' : 'order_collect', 'title' : 'Все задачи ИМ', 'count': 0, 'is_count_view' : false},
        {'route' : 'b2b_collect_actual', 'title' : 'Актуальные задачи B2B', 'count': 0, 'is_count_view' : true},
        {'route' : 'b2b_collect', 'title' : 'Все задачи B2B', 'count': 0, 'is_count_view' : false},
        {'route' : 'ecommerce_2b_collect_actual', 'title' : 'Актуальные задачи (Юридическое лицо)', 'count': 0, 'is_count_view' : true},
        {'route' : 'ecommerce_b2b_collect', 'title' : 'Все задачи (Юридическое лицо)', 'count': 0, 'is_count_view' : false},
        {'route' : 'order_collect_retail_actual', 'title' : 'Актуальные задачи Розница', 'count': 0, 'is_count_view' : true},
        {'route' : 'order_collect_retail', 'title' : 'Все задачи Розница', 'count': 0, 'is_count_view' : false},
        {'route' : 'order_shipments_return', 'title' : 'Все задачи (разукомплектация)', 'count': 0, 'is_count_view' : true},
        {'route' : 'b2b_collect_return', 'title' : 'Все задачи B2B (разукомплектация)', 'count': 0, 'is_count_view' : true}
    ]
  }),

  props: {
    filter : {
      default : false
    }
  },
  computed: {
      routeName(){
          return this.$route.name;
      }
  },
  created() {
    this.updateDataCount();
  },
  methods: {
    async updateDataCount() {
        this.is_load = false;
        await CollectService.getGroupCount(this.$route.query.filter ?? {})
        .then((response) => {
          this.$nextTick(() => {

            let countData = response['data'];
              if(HelperService.isEmpty(countData))
                return;

            this.list.forEach((tab) => {
              if(!HelperService.isEmpty(countData[tab['route']]) && tab['is_count_view'])
                tab['count'] = countData[tab['route']];
            });

          });
        }).finally(() => {
            this.is_load = true;
        });
    }
  }
}
</script>