import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";

const EmailService = {
    getLetter(hash){
        return ApiService.get(API_OMS_URL+"/shipment/email/" + hash);
    },
    updateQuantityByHash(hash, hashProduct, quantity){
        return ApiService.put(API_OMS_URL+'/shipment/email/'+hash + '/product/'+ hashProduct + '/quantity',{"quantity" : quantity}, {
            headers: ApiService.getHeader(),
        })
    },
    updateDefaultStatusByHash(hash, hashProduct){
        return ApiService.put(API_OMS_URL+'/shipment/email/'+hash + '/product/'+ hashProduct + '/default_status',{}, {
            headers: ApiService.getHeader(),
        })
    },
    emailPdf(hash){
        return ApiService.post(API_OMS_URL+'/shipment/email/'+hash+'/pdf', {}, {
            headers: ApiService.getHeader(),
        })
    },
    tasks(filter = {}, page = 1, departmentId = null){
        return ApiService.get(API_OMS_URL+'/shipment/email/tasks/collect', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page, departmentId : departmentId}
        })
    }
}

export default EmailService;