<template>
  <div class="flex flex-row justify-between p-reg_24" style="margin-top: 24px;">
      <div class="flex flex-row">

            <button type="button" class="flex justify-center items-center w-reg_32 h-reg_32 rounded border border-reg_gray_25 mr-reg_8 active:text-reg_white active:bg-reg_blue active:border-reg_blue hover:border-reg_blue" @click="changePage(prevPage)" v-if="hasPrev()">
                <span class="opacity-50">
                    <svg-icon class="icon__arrow-left w-reg_18 h-reg_18 fill-reg_black_75" name="icon__arrow-left" />
                </span>
            </button>

            <button type="button" @click.passive="changePage(1)" v-if="hasFirst()" class="flex justify-center items-center w-reg_32 h-reg_32 text-xs_reg text-reg_black_75 font-medium rounded border border-reg_gray_25 mr-reg_8 active:text-reg_white active:bg-reg_blue active:border-reg_blue hover:border-reg_blue">
                <span>1</span>
            </button>

            <button type="button" v-if="hasFirst()" class="flex justify-center items-center w-reg_32 h-reg_32 text-xs_reg text-reg_black_75 font-medium rounded border border-reg_gray_25 mr-reg_8 cursor-not-allowed">
              <span>...</span>
            </button>

            <button type="button" @click="changePage(page)" v-for="page in pages" v-bind:key="page" :class="[current === page ?'text-reg_white bg-reg_blue border-reg_blue' : 'text-reg_black_75']" class="flex justify-center items-center w-reg_32 h-reg_32 text-xs_reg font-medium rounded border border-reg_gray_25 mr-reg_8 hover:border-reg_blue">
              <span :class="{current: current === page}">{{page}}</span>
            </button>

            <button  type="button" v-if="hasLast()" class="flex justify-center items-center w-reg_32 h-reg_32 text-xs_reg text-reg_black_75 font-medium rounded border border-reg_gray_25 mr-reg_8 cursor-not-allowed">
                <span>...</span>
            </button>

            <button  type="button" @click="changePage(totalPages)" v-if="hasLast()" class="flex justify-center items-center w-reg_32 h-reg_32 text-xs_reg text-reg_black_75 font-medium rounded border border-reg_gray_25 mr-reg_8 active:text-reg_white active:bg-reg_blue active:border-reg_blue hover:border-reg_blue">
              <span>{{totalPages}}</span>
            </button>

            <button type="button" class="flex justify-center items-center w-reg_32 h-reg_32 rounded border border-reg_gray_25 mr-reg_8 active:text-reg_white active:bg-reg_blue active:border-reg_blue hover:border-reg_blue" @click="changePage(nextPage)" v-if="hasNext()">
              <span class="opacity-50">
                  <svg-icon class="icon__arrow-left w-reg_18 h-reg_18 fill-reg_black_75" name="icon__arrow-right" />
              </span>
            </button>

        </div>
    </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";

export default {
  name: "vPagination",
  components: {SvgIcon},
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 10
    },
    pageRange: {
      type: Number,
      default: 2
    }
  },
  created(){
      this.selected = this.page;
  },
  computed: {
    rangeStart() {
      const start = this.current - this.pageRange;
      return (start > 0) ? start : 1;
    },
    rangeEnd() {
      const end = this.current + this.pageRange;
      return (end < this.totalPages) ? end : this.totalPages;
    },
    pages() {
      let pages = [];

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }

      return pages;
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },
    nextPage() {
      return this.current + 1;
    },
    prevPage() {
      return this.current - 1;
    }
  },
  methods : {
      hasFirst() {
        return this.rangeStart !== 1;
      },
      hasLast() {
        return this.rangeEnd !== this.totalPages;
      },
      hasPrev() {
        return this.current > 1;
      },
      hasNext() {
        return this.current < this.totalPages;
      },
      changePage(page) {
          this.$emit('setPage', page);
      },
  }
}
</script>

<style scoped>

</style>