import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

import './assets/markup/dist/styles.css'

import ApiService from "./common/api.service";
import VueMask from 'v-mask'
import VueScrollTo from "vue-scrollto";
import AutoReload from '@/plugins/AutoReload';

Vue.config.productionTip = false

Vue.directive('click-outside', {
    bind(el, binding) {
        el.addEventListener('click', e => e.stopPropagation());
        document.body.addEventListener('click', binding.value);
    },
    unbind(el, binding) {
        document.body.removeEventListener('click', binding.value);
    }
});

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});
Vue.use(VueMask)

Vue.use(AutoReload, {
    config: {
        // модуль включен
        Enabled: true,
        // ежеминутное обновление
        CheckInterval: 300,
    },
    router: router,
    vm: this,
});

ApiService.init();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
