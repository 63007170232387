<template>
  <div>
    <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"></div>

    <div v-if="!loaded_blocks" class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center">
        <div class="block-spinner_page top"></div>
    </div>

    <div class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center" v-else>
      <div class="modal-block rounded bg-reg_white p-reg_24" :class="[width > 0 ? 'w-reg_'+width : 'w-reg_750', is_overflow ? 'overflow-auto' : '']"  style="max-width: 85%; max-height: 90%;">

        <div class="modal-block_header relative">
          <h2 class="modal-block_title text-lg_l_reg text-reg_black font-semibold" v-if="title">{{title}}</h2>
          <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mt-reg_10_m mr-reg_10_m" type="button" @click="hidePopup">
              <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75" />
          </button>
        </div>

        <p class="text-reg_black_75 text-sm_reg" v-if="text">{{text}}</p>

        <div class="modal-block_body"  style="margin-top: 15px;">

            <div v-if="loaded_blocks">
                  <div v-for="(block) in blocks" v-bind:key="block['code']" class="mx-reg_12_m flex flex-row flex-wrap">

                    <div v-for="(field) in block['fields']" v-bind:key="field['code']" :class="[block['column'] === 0 ? 'w-full' : 'w-1/'+block['column'], 'p-reg_12']" >


                      <fieldset v-if="field['type'] === 'table' && checkField(field)" :disabled="is_disabled">
                          <v-table
                               :with_padding_x="false"
                               class_not_found = ""
                               class_block=""
                               class_table_block=""
                               :is_xlsx="false"
                               :table="field['table']"
                               :items="field['value']"
                               :loaded="true"
                               :loaded_filter="true"
                               :info="[]"
                               :pagination_data="{}"
                               @_update_item="change"
                          />
                      </fieldset>

                      <div v-if="field['type'] === 'info_text'">
                        <span class="text-sm_reg text-reg_black mr-reg_12"  v-for="(value) in field['value']" v-bind:key="value['title']" >{{value['title']}}: <b>{{value['value']}}</b></span>
                      </div>

                      <ui-input v-else-if="field['type'] === 'text' && checkField(field)"
                          :label="field['name']"
                          type="text"
                          :name_custom="field['code']"
                          :name="field['code']"
                          v-model="field['value']"
                          class_block="relative"
                          class_label="relative text-sm_reg text-reg_black font-semibold"
                          class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                          placeholder=""
                          :required="field['required']"
                          :error="errors[field['code']]"
                          :disabled="field['disabled']"
                          @clearError="clearError"
                      />

                      <ui-input v-else-if="field['type'] === 'mask' && checkField(field)"
                            :label="field['name']"
                            type="text"
                            :name_custom="field['code']"
                            :mask="field['mask']"
                            :name="field['code']"
                            v-model="field['value']"
                            class_block="relative"
                            class_label="relative text-sm_reg text-reg_black font-semibold"
                            class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                            placeholder=""
                            :required="field['required']"
                            :error="errors[field['code']]"
                            @clearError="clearError"
                      />

                      <div class="" v-else-if="field['type'] === 'file' && checkField(field)" >
                        <ui-input
                            :label="field['name']"
                            type="file"
                            :name_custom="field['code']"
                            :name="field['code']"
                            v-model="field['value']"
                            class_block="relative"
                            class_label="relative text-sm_reg text-reg_black font-semibold"
                            class_input="block border border-reg_gray_75 mt-reg_8 w-full rounded text-sm_reg text-reg_black py-reg_9 px-reg_14"
                            placeholder=""
                            :required="field['required']"
                            :error="errors[field['code']]"
                            :description="field['description']"
                            @fileInput="fileInput"
                            @clearError="clearError"
                        />
                      </div>

                      <ui-checkbox v-else-if="field['type'] === 'checkbox' && checkField(field)"
                          :name="field['code']"
                          :label="field['name']"
                          v-model="field['value']"
                          @input="(name, value) => {field['value'] = value;}"
                      />

                      <div class="" v-else-if="field['type'] === 'date' && checkField(field)" >
                        <label v-if="field['name']"
                               class="relative text-sm_reg text-reg_black font-semibold"
                               :class="[field['class_label'] ? field['class_label'] : '', field['required'] ? 'attention' : '']">{{field['name']}}</label>
                        <div class="relative py-reg_8">
                            <date-picker
                                 :lang="ru"
                                 :currentValue="field['value']"
                                 :range = "field['range']"
                                 format="DD.MM.YYYY"
                                 v-model="field['value']"
                                 type="date"
                                 :disabled-date="(date) => {return checkDate(date, field);}"
                                 valueType="DD.MM.YYYY"
                                 :required="field['required']"
                                 @change="(value) => {
                                    field['value'] = value;
                                 }"
                            />
                        </div>
                        <p class="text-reg_red text-xs_reg" v-if="errors[field['code']]">{{errors[field['code']]}}</p>
                      </div>

                      <ui-select
                          v-else-if="field['type'] === 'select' && checkField(field)"
                          :name="field['code']"
                          v-model="field['value']"
                          :options="field['options']"
                          :id="'field_'+'_'+field['code']"
                          class_block="table md:w-full mr-reg_24 cursor-default"
                          class_label="relative text-sm_reg text-reg_black font-semibold"
                          class_description="block text-reg_gray_50 text-xs_reg mt-reg_4"
                          :label="field['name']"
                          :description="field['description']"
                          :searchable="false"
                          :is_open_dropdown="true"
                          :reduce="option => option.code"
                          :disabled="field['disabled']"
                          label_select="title"
                          @option:selected="(option) => {
                              option = {...option};
                             if(field['reset_title'])
                               resetTitleButtonSave();
                             field['value'] = option['code'];
                             clearError(field['code']);
                             if(!!field['check_field'])
                                checkGroup(field['value'], field['code'], field['check_field'], field['check_field_param'], field['modify_field_param']);
                          }"
                          :error="errors[field['code']]"
                          :required="field['required']"
                      />


                      <ui-checkbox-group
                          v-else-if="field['type'] === 'checkbox_group' && checkField(field)"
                          :name="field['code']"
                          :label="field['name']"
                          :variants="field['variants']"
                          @change="(key, value) => {field['variants'][key]['checked'] = value;}"
                          :column="field['column']"
                      />

                    </div>

                  </div>

                  <div class="flex flex-row justify-between mt-reg_12" v-if="!is_disabled">
                      <div class="flex flex-row">
                          <button @click="save()" :class="[!loaded_button ? 'block-spinner_page block-spinner_20 top spinner_button' : '']" type="button" class="flex flex-row bg-reg_green hover:bg-reg_green_100 rounded font-medium text-reg_white text-sm_reg py-reg_10 px-reg_20">
                            <svg-icon name="icon__sim-card" class="icon__sim-card w-reg_18 h-reg_18 fill-reg_white mr-reg_8" />
                            {{title_buttons.save}}
                          </button>
                          <button type="button"  @click="hidePopup" class="block ml-reg_24 rounded bg-reg_gray hover:bg-reg_gray_10 font-medium text-reg_black_75 text-sm_reg py-reg_10 px-reg_20">Отменить</button>
                      </div>
                  </div>
                </div>

        </div>

      </div>
    </div>

  </div>

</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import PopupService from "@/common/services/popup.service";
import UiInput from "@/components/ui/form/elements/input.vue";
import UiSelect from "@/components/ui/form/elements/select.vue";
import UiCheckbox from "@/components/ui/form/elements/checkbox";
import HelperService from "@/common/services/helper.service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ru from 'vue2-datepicker/locale/ru';
import UiCheckboxGroup from "@/components/ui/form/elements/checkbox-group";
import VTable from "@/components/ui/table";

export default {
  name: "popupEdit",
  components: {UiCheckboxGroup, UiSelect, UiInput, SvgIcon, DatePicker, UiCheckbox, VTable},
  data: () => ({
      title : "",
      name : "",
      blocks : [],
      items : [],
      info : [],
      width : 750,
      loaded_blocks : false,
      loaded_button : true,
      allow : true,
      errors : {},
      is_overflow : false,
      ru : ru,
      text : "",
      form_data: new FormData(),
      title_buttons : {
          save : "Сохранить"
      },
      title_buttons_default : {
          save : "Сохранить"
      },
      data_backend : {},
      is_disabled : false
  }),
  props: {
      type : {
        type : String,
        require : true
      },
      item_id : {
        type : Number,
        require : true
      },
      payload : {},
  },
  created(){
      this.$nextTick(() => {
          this.$root.$emit("notificationHide");
          this.getBlocks();
      });
  },
  methods: {
    clearError(code){
        this.errors[code] = null;
    },
    setTitleButtonSave(text = ""){
        this.title_buttons.save = text;
    },
    resetTitleButtonSave(){
        this.title_buttons.save = this.title_buttons_default.save;
    },
    async change(){
      await PopupService.change(this.type, this.item_id, this._getFieldsValue(), this.payload)
          .then((response) => {
            this.setData(response.data);
          })
    },
    async save(){

      this.loaded_button = false;
      this.errors = {};

      this.$root.$emit("notificationHide");

      let title = this.item_id > 0 ? "Редактирование записи" : "Создание записи";

      let values = !HelperService.isEmpty(this.payload['order_id']) ? this._getFieldsValue() : this.getFieldsValue();

      await PopupService.saveEdit(this.item_id, values, this.type, this.payload)
          .then((response) => {

            let message = this.item_id > 0 ? 'Успешно обновлено' : 'Успешно добавлено';
            if(!HelperService.isEmpty(response.data.message))
              message = response.data.message;

            this.$root.$emit("notificationShow", {title : title, type : "info", "text" : message});
            setTimeout(() => {
              this.$root.$emit("_popup_edit_hide");
              this.$root.$emit("_update_page_custom");

            }, 50);

          }).catch((response) => {
              let errors = response.data.message.errors_format;

              if(!HelperService.isEmpty(errors['form_fields']))
                  this.errors = errors['form_fields'];
              else if(!HelperService.isEmpty(errors['all']))
                  this.$root.$emit("notificationShow", {title : title, type : "error", "text" : errors['all']});
              else
                  this.$root.$emit("notificationShow", {title : title, type : "error", "text" : "-"});
          })
          .finally(() => {

            setTimeout(() => {
              this.loaded_button = true;
            }, 250);

          });
    },
    async getBlocks(){

      this.beforeGetBlocks();

      await PopupService.getEdit(this.type, this.item_id, this.payload)
          .then((response) => {
            this.setData(response.data);
          }).finally(() => {
            this.afterGetBlocks();
          });

    },
    loaderFinish(){
      setTimeout(() => {
        this.loaded_blocks = true;
      }, 200);
    },
    setBlocks(blocks){
      this.blocks = blocks;
    },
    setData(data){
        this.title = data['title'];
        this.width = data['width'];
        this.is_overflow = data['is_overflow'];

        this.text = data['text'];
        this.setBlocks(data.blocks);
        let data_backend = this.data_backend = data['data'];

        if(data_backend['is_disabled'])
            this.is_disabled = data_backend['is_disabled'];

        if(this.data_backend['title_buttons_default'])
            this.title_buttons_default = this.data_backend['title_buttons_default'];

        this.resetTitleButtonSave()
    },
    beforeGetBlocks(){
        this.loaded_blocks = false;
    },
    afterGetBlocks(){
        this.loaderFinish();
    },
    hidePopup(){
        this.$root.$emit("notificationHide");
        this.$root.$emit("_popup_edit_hide")
    },
    checkField(field){

      if(HelperService.isEmpty(field['show_if_list']) && HelperService.isEmpty(field['show_if']) && HelperService.isEmpty(field['show_not_if']))
        return true;

      let success = false;

      if(field['show_if_list']){

          let _field_backend = field['show_if_list']
          let code = _field_backend['code'];
          let value = null;

          this.blocks.forEach((block) =>  {
            block['fields'].forEach((field) =>  {
              if(field['code'] === code && field['value'])
                value = field['value'];
            });
          });

          if(value){

            let _list = this.data_backend[_field_backend['data_column_list']];

            _list.forEach((item) =>  {
                if(item['value'] === value){
                    if(item[_field_backend['data_column']] === _field_backend['data_value']){
                        success = true;

                        if(_field_backend['title_button_save'])
                          this.setTitleButtonSave(_field_backend['title_button_save'])

                        field['value'] = item[field['data_value']]
                    }
                }
            });
          }
      }


      if(field['show_if']){
          let code = field['show_if']['code'];
          let value = field['show_if']['value'];

        this.blocks.forEach((block) =>  {
          block['fields'].forEach((field) =>  {
            if(field['code'] === code && field['value'] === value)
              success = true;
          });
        });

      }

      if(field['show_not_if']){
        let code = field['show_not_if']['code'];
        let value = field['show_not_if']['value'];

        this.blocks.forEach((block) =>  {
          block['fields'].forEach((field) =>  {

            if(field['code'] === code && field['value'] !== value)
              success = true;
          });
        });

      }

      return success;
    },
    getFieldsValue(){

      this.blocks.forEach((block) =>  {
        block['fields'].forEach((field) =>  {

          switch (field['type']) {
            case 'checkbox_group':
              field['variants'].forEach((variant, key) => {
                if(variant['checked']){
                  this.form_data.set(`${field['code']}[${key}]`, variant['id']);
                }
              });
              break;
            case 'file':
              break;
            case 'date':
              if(field['range']){
                this.form_data.set(`${field['code']}[0]`, field['value'] ? field['value'][0] : '');
                this.form_data.set(`${field['code']}[1]`, field['value'] ? field['value'][1] : '');
              }
              else{
                this.form_data.set(field['code'], field['value'] ? field['value'] : '');
              }
              break;
            default:
              this.form_data.set(field['code'], field['value'] ? field['value'] : '');
          }
        });
      });

      this.form_data.set('type', this.type);
      this.form_data.set('item_id', this.item_id);

      return this.form_data;
    },
    _getFieldsValue(){

      let result = [];
      this.blocks.forEach((block) =>  {
        block['fields'].forEach((field) =>  {
          result[field['code']] = field['value'];
        });
      });

      result = Object.assign({}, result);

      return result;
    },
    checkGroup(value, code, check_field, check_field_param, modify_field_param){
        this.blocks.forEach((block) =>  {
          block['fields'].forEach((field) =>  {
            if(field['code'] === check_field){
                field['variants'].forEach((variant) =>  {
                    variant[modify_field_param] = false;
                    variant['checked'] = false;

                    if(variant[check_field_param] === value)
                        variant[modify_field_param] = true;
                });
            }
          });
        });
    },
    fileInput(name, file) {
      this.form_data.set(name, file);
    },
    checkDate(date, field) {

      let result = false;

      if(field['date_from'])
        result = date < new Date(field['date_from']);

      if(field['days']){
        let day = date.getDay();
        if(day === 0)
          day = 7;

        result = result || !field['days'].includes(day);
      }

      return result;
    }
  }
}
</script>

<style>
.vs--disabled input:disabled{
    display: none;
}
</style>