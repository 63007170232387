//user
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_USER_BY_PHONE = "setUserByPhone";
export const SET_MENU_STATUS = "setMenuStatus";
export const SET_USER_INFO = "setUserInfo";
export const SET_HELPER_ORDER_STATUSES = "setHelperOrderStatuses";
export const SET_HELPER_SHIPMENT_STATUSES = "setHelperShipmentStatuses";
export const SET_HELPER_ORDER_STATUSES_SETTINGS = "setHelperOrderStatusesSettings";
export const SET_HELPER_ACTIVE_REGIONS = "setHelperActiveRegions";
export const SET_HELPER_ACTIVE_SHOPS = "setHelperActiveShops";
export const SET_HELPER_ACTIVE_SHOPS_REAL = "setHelperActiveShopsReal";
export const SET_HELPER_STORES = "setHelperStores";

//email
export const SET_LETTER = "setLetter";
export const SET_QUANTITY_BY_HASH = "setQuantityByHash";
export const SET_DEFAULT_STATUS_BY_HASH = "setDefaultStatusByHash";

//order
export const SET_ORDER_CREATE_CONTACT = "setOrderCreateContact";
export const SET_ORDER_CREATE_CONTACT_LOCAL = "setOrderCreateContactLocal";
export const SET_ORDER_SELECTS = "setOrderSelects";
export const SET_CITIES = "setCities";
export const SET_LOCATIONS = "setLocations";
export const SET_ORDER_CREATE_DELIVERY = "setOrderCreateDelivery";
export const SET_ORDER_CREATE_DELIVERY_LOCAL = "setOrderCreateDeliveryLocal";
export const UPDATE_ORDER = "updateOrder";
export const UPDATE_ORDER_CONTACT = "updateOrderContact";
export const UPDATE_ORDER_CONTACT_B2B = "updateOrderContactB2b";
export const SET_CANCEL_ORDER_CONTACT = "setCancelOrderContact";
export const SET_CANCEL_ORDER_CONTACT_B2B = "setCancelOrderContactB2b";
export const SET_CANCEL_ORDER_DELIVERY = "setCancelOrderDelivery";
export const UPDATE_ORDER_PRODUCTS = "updateOrderProducts";
export const SET_CANCEL_ORDER_PRODUCTS = "setCancelOrderProducts";
export const SET_CONTACT_AFTER_FILL = "setContactAfterFill";
export const SET_DB_ORDER_CREATE_CONTACT = "setDbOrderCreateContact";
export const _DELETE_COUPON = "_deleteCoupon";



export const SET_ORDER_ADDITIONAL_INFO = "setOrderAdditionalInfo";

export const SET_ORDER_CREATE_PAYMENT_LOCAL = "setOrderCreatePaymentLocal";
export const SET_ORDER_CREATE_PAYMENT = "setOrderCreatePayment";
export const SET_CANCEL_ORDER_PAYMENT = "setCancelOrderPayment";

//comment
export const SET_CANCEL_ORDER_COMMENT = "setCancelOrderComment";
export const SET_ORDER_CREATE_COMMENT_LOCAL = "setOrderCreateCommentLocal";
export const SET_ORDER_CREATE_COMMENT = "setOrderCreateComment";
export const UPDATE_ORDER_COMMENT = "updateOrderComment";
export const UPDATE_ORDER_COMMENT_FOR_LOGIST = "updateOrderCommentForLogist";


