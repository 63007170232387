<template>
    <div :class="[class_block ? class_block : '']">
      <label :class="[class_label ? class_label : '', required ? 'attention' : '']" v-if="label">
          {{label}}
      </label>

      <div class="relative">
        <base-input v-if="mask"
            :disabled="disabled"
            :id="name"
            :name="name_custom"
            :type="type"
            :value="data_value"
            v-bind="$attrs"
            v-on="$listeners"
            v-on:change.native="changeValue"
            v-on:blur.native="changeValue"
            v-on:keyup.native="clearError"
            v-on:focusin.native="focusIn"
            :class="[class_input, error ? 'bg-reg_pink_20 border-reg_red' : '']"
            :placeholder="placeholder"
            v-mask=mask
            :step="step"
        />
        <base-input v-else
            :disabled="disabled"
            :id="name"
            :name="name_custom"
            :type="type"
            :value="data_value"
            v-bind="$attrs"
            v-on="$listeners"
            v-on:change.native="changeValue"
            v-on:keyup.native="clearError"
            v-on:focusin.native="focusIn"
            :class="[class_input, error ? 'bg-reg_pink_20 border-reg_red' : '']"
            :placeholder="placeholder"
            :min="min"
            :step="step"
            :max="max"
        />

          <svg-icon-copy
              v-if="icon === 'success' && title_icon && value"
              class="icon__copy absolute right-reg_11 bottom-reg_11 w-reg_18 h-reg_18"
              name="icon__copy"
              :title="title_icon"
              :value_input="value"
              :mask-number="mask === '375 ## ### ## ##' ? true: false"
          />
          <svg-icon
              v-else-if="icon === 'success' && !title_icon"
              class="icon__check absolute fill-reg_green right-reg_11 bottom-reg_11 w-reg_18 h-reg_18"
              name="icon__check" />
      </div>

      <p class="absolute text-reg_red text-xs_reg" v-if="error">{{error}}</p>
      <p class="block text-xs_reg mt-reg_4" :class="[class_info]" v-else-if="info">{{info}}</p>
      <p class="block text-reg_gray_50 text-xs_reg mt-reg_4" :class="[class_description ? class_description : '']" v-else-if="description"  >{{description}}</p>

      <slot name="addition_func"></slot>
      <slot name="addition_func2"></slot>

    </div>
</template>

<script>
    import BaseInput from "@/components/ui/form/elements/baseInput";
    import SvgIcon from "@/components/ui/svgicon";
    import SvgIconCopy from "@/components/ui/svgiconcopy.vue";

    export default {
        name: 'UiInput',
        data: () => ({
            data_value : null
        }),
        components: {
          SvgIconCopy,
          SvgIcon,
            BaseInput
        },
        inheritAttrs: false,
        props: {
            name_custom: {
                type: String,
                required: false
            },
            name: {
                required: false
            },
            disabled: {},
            label: String,
            value: {},
            step: {},
            placeholder: String,
            type: {
                type: String,
                default: 'text',
                validator(value) {
                    return [
                        'text',
                        'number',
                        'email',
                        'password',
                        'date',
                        'time',
                        'textarea',
                        'file'
                    ].includes(value);
                }
            },
            error: String,
            description : String,
            class_input : {},
            class_block : String,
            class_label : String,
            class_info : String,
            class_description : String,
            icon: {
                type: String,
                required: false
            },
            title_icon: String,
            info : String,
            key_input : Number,
            type_input : String,
            mask : String,
            required : Boolean,
            min : {},
            max : {}
        },
        watch: {
            value : "setDataValue",
        },
        methods: {
            clearError(){
                this.$emit("clearError", this.name);
            },
            changeValue(e){
                let value = this.value;

                if(this.type === 'number'){
                    if(this.step === 0.01)
                      value = Math.round(Number(value) * 100) / 100;
                    else if(this.step !== 1)
                      value = Number(value).toFixed(3);
                    else
                      value = Math.round(Number(value));
                }

                if(Number(this.max)>0){
                    if(Number(value)>(this.max)){
                        this.data_value = this.max;
                        this.$emit("changeValue", this.name, this.max);
                        return;
                    }
                }

              if(Number(this.min)>0){
                  if(Number(value)<(this.min)){
                      this.data_value = this.min;
                      this.$emit("changeValue", this.name, this.min);
                      return;
                  }
              }

              if(this.type === 'file'){
                  this.$emit("fileInput", this.name, e.target.files[0]);
                  return;
              }

              this.$emit("changeValue", this.name, value);
            },
            setDataValue(){
                this.data_value = this.value;
            },
            focusIn(){
                this.$emit("focusIn", this.name);
            },
        },
        mounted() {
            this.setDataValue();
        },
    };
</script>
