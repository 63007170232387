import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {API_OMS_URL} from "@/common/config";
import JwtService from "@/common/jwt.service";
import router from "@/router";


axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
            return response;
        } else {
            return Promise.reject(response);
        }
    },

    error => {

        if (error.response.status === 401) {

            JwtService.destroyToken();

            try {
                if(router.currentRoute.name !== 'auth')
                    window.location.href = "/auth";
            } catch (err){
                console.log(err);
            }


        }
        return Promise.reject(error.response);
    }
);

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_OMS_URL;
    },
    getHeader() {
        return {
            "Content-type": "application/json",
            "Authorization-Token": `${JwtService.getToken()}`,
        }
    },
    get(resource, config) {
        return Vue.axios.get(resource, config);
    },
    post(resource, params, config) {
        return Vue.axios.post(`${resource}`, params, config);
    },
    put(resource, params, config) {
        return Vue.axios.put(`${resource}`, params, config);
    },
    delete(resource, config) {
        return Vue.axios.delete(`${resource}`, config);
    },
};

export default ApiService;
