import download from 'downloadjs'

const HelperService = {
    formatDate(date) {
        if(date.length<=0)
            return "";

        let dateObject = new Date(date);
        return ("0" + (dateObject.getDate())).slice(-2) + '.' + ("0" + (dateObject.getMonth() + 1)).slice(-2) + '.' + dateObject.getFullYear();
    },
    isEmpty(str) {
        return (typeof str === "undefined" || str === null || str === "");
    },
    isEmptyArray(arr) {
        return arr.length == 0
    },
    getFirstElement(items){
        if(typeof items !== 'undefined'){
            let item = items.slice(0, 1)[0];
            return item;
        }
        return null;
    },
    getLastElement(items){
        if(typeof items !== 'undefined'){
            let item = items[items.length - 1];
            return item;
        }
        return null;
    },
    getElement(items, index, value){
        return items.find(function(element) {
            return element[index] == value;
        });
    },
    contains(arr, elem) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === elem) {
                return true;
            }
        }
        return false;
    },
    containsObject(arr, id){
        for(var i = 0; i < arr.length; i++) {
            if (arr[i].id === id)
                return true;
        }
        return false;
    },
    getObValue(ob, key){
        if(!this.isEmpty(ob) && !this.isEmpty(ob[key]))
            return ob[key];
    },
    updateObject(ob, newValues){
        if(!this.isEmpty(newValues)){
            for (let key in newValues) {
                ob[key] = newValues[key];
            }
        }
    },
    updateValueIfExist(ob, val){
        if(!this.isEmpty(val))
            ob = val;
    },
    getFilterData(filter){
        if(!this.isEmpty(filter)){
            let decoded = window.atob(filter)
            let originalX = JSON.parse(decoded);

            return originalX.filter;
        }

        return false;
    },
    setLocationFilter(filterData){
        let x = {filter: filterData}
        let stringRep = JSON.stringify(x)
        let encoded = window.btoa(stringRep)

        let u = new URLSearchParams({filter : encoded}).toString();
        let baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
        let newUrl = baseUrl + '?' + u;

        window.location.href= newUrl;
    },
    utf8_to_b64(str) {
        return window.btoa(unescape(encodeURIComponent(str)));
    },
    b64_to_utf8(str) {
        return decodeURIComponent(escape(window.atob(str)));
    },
    setFilterParams(filterData){

        filterData = {...filterData};

        Object.keys(filterData).forEach(key => {
            if (filterData[key] === '') {
                delete filterData[key];
            }
        });

        let x = {filter: filterData}
        let stringRep = JSON.stringify(x);

        let encoded = this.utf8_to_b64(stringRep);

        return encoded;
    },
    getFilterParamsData(filter){
        if(!this.isEmpty(filter)){

            let decoded = this.b64_to_utf8(filter);
            let originalX = JSON.parse(decoded);

            return originalX.filter;
        }

        return false;
    },
    numWord(value, words){
        value = Math.abs(value) % 100;
        let num = value % 10;
        if(value > 10 && value < 20) return words[2];
        if(num > 1 && num < 5) return words[1];
        if(num == 1) return words[0];
        return words[2];
    },
    saveXlsx(response){
        download(
            response.data,
            response.type,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml'
        )
    }
}

export default HelperService;
