import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";

const CollectB2bService = {
    getItemByHash(hash){
        return ApiService.get(API_OMS_URL+"/b2b/shipments/collect/" + hash, {
            headers: ApiService.getHeader(),
        });
    },
    setQuantityByHash(hash, hashProduct, quantity){
        return ApiService.put(API_OMS_URL+'/b2b/shipments/collect/'+hash + '/product/'+ hashProduct + '/quantity',{"quantity" : quantity}, {
            headers: ApiService.getHeader(),
        })
    },
    setDefaultStatusByHash(hash, hashProduct){
        return ApiService.put(API_OMS_URL+'/b2b/shipments/collect/'+hash + '/product/'+ hashProduct + '/default_status',{}, {
            headers: ApiService.getHeader(),
        })
    },
    createPdf(hash, withPictures = false){
        return ApiService.post(API_OMS_URL+'/b2b/shipments/collect/'+hash+'/pdf', {with_pictures : withPictures}, {
            headers: ApiService.getHeader(),
        })
    },
    getCollect(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/b2b/shipments/collect', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getCollectActual(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/b2b/shipments/collect/actual', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    setEmployeeIdByHash(hash, employeeId){
        return ApiService.put(API_OMS_URL+'/b2b/shipments/collect/'+hash + '/employee/'+ employeeId,{}, {
            headers: ApiService.getHeader(),
        })
    },
    getPageCollectReturn(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/b2b/shipments/collect/return", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getCollectReturnTaskById(taskId){
        return ApiService.get(API_OMS_URL+"/b2b/shipments/collect/return/" + taskId, {
            headers: ApiService.getHeader(),
        });
    },
    setCollectReturnTaskEmployee(id, employeeId){
        return ApiService.put(API_OMS_URL+'/b2b/shipments/collect/return/' + id + '/employee/'+ employeeId,{}, {
            headers: ApiService.getHeader(),
        })
    },
    createCollectReturnTaskPdf(id, withPictures = false){
        return ApiService.post(API_OMS_URL+'/b2b/shipments/collect/return/'+id+'/pdf', {with_pictures : withPictures}, {
            headers: ApiService.getHeader(),
        })
    },
}

export default CollectB2bService;