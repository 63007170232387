<template>
    <input :value="value" :type="type" v-on="inputListeners" />
</template>

<script lang="js">
    import Vue from "vue";
    export default Vue.extend({
        name: "BaseInput",
        props: {
            value: {
                default: '',
            },
            type: {
                type: String,
                default: "text",
            }
        },
        data() {
            const initialValue = this.value;
            return {
                inputValue: initialValue
            };
        },
        computed: {
            inputListeners() {
                const vm = this;
                return {
                    ...vm.$listeners,
                    input: event => vm.$emit("input", event.target.value),
                    change: event => vm.$emit("change", event.target.value),
                };
            }
        },
        updated() {
            this.inputValue = this.value;
        },
    });
</script>