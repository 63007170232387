import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";

const CollectService = {
    getItemByHash(hash){
        return ApiService.get(API_OMS_URL+"/shipment/collect/" + hash, {
            headers: ApiService.getHeader(),
        });
    },
    setQuantityByHash(hash, hashProduct, quantity){
        return ApiService.put(API_OMS_URL+'/shipment/collect/'+hash + '/product/'+ hashProduct + '/quantity',{"quantity" : quantity}, {
            headers: ApiService.getHeader(),
        })
    },
    setDefaultStatusByHash(hash, hashProduct){
        return ApiService.put(API_OMS_URL+'/shipment/collect/'+hash + '/product/'+ hashProduct + '/default_status',{}, {
            headers: ApiService.getHeader(),
        })
    },
    createPdf(hash, withPictures = false){
        return ApiService.post(API_OMS_URL+'/shipment/collect/'+hash+'/pdf', {with_pictures : withPictures}, {
            headers: ApiService.getHeader(),
        })
    },
    getTasksEcommerce(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/collect/ecommerce/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getTasksEcommerceActual(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/collect/ecommerce/actual/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getTasksRetail(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/collect/retail/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getTasksRetailActual(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/collect/retail/actual/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getTasksEcommerceB2b(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/collect/ecommerce/b2b/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getTasksEcommerceB2bActual(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/collect/ecommerce/b2b/actual/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    setEmployeeIdByHash(hash, employeeId){
        return ApiService.put(API_OMS_URL+'/shipment/collect/'+hash + '/employee/'+ employeeId,{}, {
            headers: ApiService.getHeader(),
        })
    },
    getItemReturn(id){
        return ApiService.get(API_OMS_URL+"/shipment/collect/return/" + id, {
            headers: ApiService.getHeader(),
        });
    },
    setEmployeeReturn(id, employeeId){
        return ApiService.put(API_OMS_URL+'/shipment/collect/return/' + id + '/employee/'+ employeeId,{}, {
            headers: ApiService.getHeader(),
        })
    },
    pdfReturn(id, withPictures = false){
        return ApiService.post(API_OMS_URL+'/shipment/collect/return/'+id+'/pdf', {with_pictures : withPictures}, {
            headers: ApiService.getHeader(),
        })
    },
    getGroupCount(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/shipment/collect/group/count", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page:page}
        });
    }
}

export default CollectService;