import JwtService from "@/common/jwt.service";
import UserService from "@/common/services/user.service";

import {
    LOGIN,
    LOGOUT,
    UPDATE_MENU_STATUS,
    CREATE,
    GET_USER_INFO
} from "./actions.type";

import {
    SET_AUTH,
    PURGE_AUTH,
    SET_MENU_STATUS,
    SET_USER_INFO,
    SET_HELPER_ORDER_STATUSES,
    SET_HELPER_SHIPMENT_STATUSES,
    SET_HELPER_ORDER_STATUSES_SETTINGS,
    SET_HELPER_ACTIVE_REGIONS,
    SET_HELPER_ACTIVE_SHOPS,
    SET_HELPER_ACTIVE_SHOPS_REAL,
    SET_HELPER_STORES

} from "./mutations.type";

const state = {
    menu_left : UserService.getMenuLeft() ?? false,
    is_authenticated: !!JwtService.getToken(),
    info : {
        user_title : null,
        roles : [],
        is_button_create_shipments : false,
        is_col_center : false,
        is_logistic : false,
        is_tablet : false,
        is_tablet_only : false,
        is_problem : false,
        store_id : null,
        is_consolidation : false,
        is_head : false,
        is_admin : false,
        is_collect : false,
        is_report : false,
        is_b2b : false,
        is_on_order : false,
        is_increase_call_center : false,
        employees : [],
        departments : [],
        departments_for_select : [],
        departments_product : []
    },
    order_helper : {
        statuses : [],
        statuses_settings : [],
        active_regions : [],
        active_shops : [],
        active_shops_real : [],
        problems_types : [],
        payments_types : []
    },
    shipment_helper : {
        statuses : []
    },
    helper : {
        stores : []
    }
};

const getters = {
    getHelperActiveRegionsSettings(){
        return state.order_helper.active_regions;
    },
    getHelperOrderStatusesSettings(){
        return state.order_helper.statuses_settings;
    },
    getHelperOrderShops(){
        return state.order_helper.active_shops;
    },
    getHelperOrderShopsReal(){
        return state.order_helper.active_shops_real;
    },
    getHelperOrderStatuses(){
        return state.order_helper.statuses;
    },
    getHelperShipmentStatuses(){
        return state.shipment_helper.statuses;
    },
    isAuthenticated(state) {
        return state.is_authenticated;
    },
    getMenuStatus(){
        return state.menu_left;
    },
    isButtonCreateShipments(){
        return state.info.is_button_create_shipments;
    },
    getUserStoreId(){
        return state.info.store_id;
    },
    getUserTitle(){
        return state.info.user_title;
    },
    isColCenter(){
        return state.info.is_col_center;
    },
    isIncreaseCallCenter(){
        return state.info.is_increase_call_center;
    },
    isProblem(){
        return state.info.is_problem;
    },
    isLogistic(){
        return state.info.is_logistic;
    },
    isConsolidation(){
        return state.info.is_consolidation;
    },
    isHead(){
        return state.info.is_head;
    },
    isTablet(){
        return state.info.is_tablet;
    },
    isOnOrder(){
        return state.info.is_on_order;
    },
    isB2b(){
        return state.info.is_b2b;
    },
    isTabletOnly(){
        return state.info.is_tablet_only;
    },
    isAdmin(){
        return state.info.is_admin;
    },
    isCollect(){
        return state.info.is_collect;
    },
    isReport(){
        return state.info.is_report;
    },
    getEmployees(){
        return state.info.employees;
    },
    getDepartmentsSelect(){
        return state.info.departments_for_select;
    },
    getDepartments(){
        return state.info.departments;
    },
    getDepartmentsProduct(){
        return state.info.departments_product;
    },
    storesList(){
        return state.helper.stores;
    },
};

const actions = {
    async [GET_USER_INFO](context) {
        let response = await UserService.getInfo();

        if(response.status === 200){
            await context.commit(SET_USER_INFO, response.data.info);
            await context.commit(SET_HELPER_ORDER_STATUSES, response.data.helper_oms.orders_statuses);
            await context.commit(SET_HELPER_ORDER_STATUSES_SETTINGS, response.data.helper_oms.orders_statuses_settings);
            await context.commit(SET_HELPER_SHIPMENT_STATUSES, response.data.helper_oms.shipment_statuses);
            await context.commit(SET_HELPER_ACTIVE_REGIONS, response.data.helper_oms.active_regions);
            await context.commit(SET_HELPER_ACTIVE_SHOPS, response.data.helper_oms.active_shops);
            await context.commit(SET_HELPER_ACTIVE_SHOPS_REAL, response.data.helper_oms.active_shops_real);
            await context.commit(SET_HELPER_STORES, response.data.helper_oms.stores);
        }

        return response;
    },
    async [LOGIN](context, credentials) {
        let response = await UserService.login(credentials);

        if(response.status === 200){
            await context.commit(SET_AUTH, response.data.message);
        }

        return response;
    },
    async [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    async [UPDATE_MENU_STATUS](context, status) {
        context.commit(SET_MENU_STATUS, status);
    },
    async [CREATE](context, phone) {
        let response = await UserService.create(phone);
        return response;
    },
};

const mutations = {

    [SET_AUTH](state, data) {
        state.is_authenticated = true;
        JwtService.saveToken(data['access_token']);
        JwtService.saveTokenRefresh(data['refresh_token']);
    },
    [PURGE_AUTH](state) {
        state.is_authenticated = false;
        JwtService.destroyToken();
        JwtService.destroyTokenRefresh();
    },
    [SET_MENU_STATUS](state, status) {
        UserService.setMenuLeft(status)
    },
    [SET_USER_INFO](state, data) {
        state.info = data;
    },
    [SET_HELPER_ORDER_STATUSES](state, data) {
        state.order_helper.statuses = data;
    },
    [SET_HELPER_ORDER_STATUSES_SETTINGS](state, data) {
        state.order_helper.statuses_settings = data;
    },
    [SET_HELPER_SHIPMENT_STATUSES](state, data) {
        state.shipment_helper.statuses = data;
    },
    [SET_HELPER_ACTIVE_REGIONS](state, data) {
        state.order_helper.active_regions = data;
    },
    [SET_HELPER_ACTIVE_SHOPS](state, data) {
        state.order_helper.active_shops = data;
    },
    [SET_HELPER_ACTIVE_SHOPS_REAL](state, data) {
        state.order_helper.active_shops_real = data;
    },
    [SET_HELPER_STORES](state, data) {
        state.helper.stores = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};