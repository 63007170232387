<template>
    <div class="absolute z-10 left-0 bg-reg_white w-full" :class="[is_active && show ? '' : 'hidden']" >
      <div class="h-reg_400 overflow-hidden rounded border border-reg_blue p-reg_15">
        <div class="block-scroll h-full overflow-auto pr-reg_15">
          <div class="table w-full table-auto border-collapse">
            <div class="table-row-group">

              <router-link class="table-row border-t border-reg_gray_10 hover:bg-reg_gray" :to="{ name: 'order_edit', params: { id: order.id} }"  v-for="(order, key) in orders" v-bind:key="key">
                  <div class="w-reg_15_100 table-cell align-middle p-reg_12">
                    <div class="flex flex-row items-center">
                      <div class="checkbox-table relative" v-if="!order['status']['is_cancel']">
                          <input :checked="order['status']['is_finish'] ? 'checked' : ''" name="check" type="checkbox" class="opacity-0 h-reg_16 w-reg_16 absolute z-3">
                          <span class="input-fake flex items-center justify-center h-reg_16 w-reg_16 border-2 border-reg_gray_25 rounded-full">
                              <svg-icon name="icon__check-2" class="icon__check-2 hidden w-reg_10 h-reg_10 fill-reg_white" />
                          </span>
                      </div>
                      <div class="checkbox-table relative" v-else>
                          <status-icon color="red" />
                      </div>
                      <div class="ml-reg_14">
                        <p class="text-xs_l_reg text-reg_blue">{{order['id']}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="w-reg_20_100 table-cell align-middle p-reg_12">
                    <p class="text-xs_l_reg text-reg_gray_60">Создан:</p>
                    <p class="text-xs_l_reg text-reg_black">{{order['date_insert']}}</p>
                  </div>
                  <div class="w-reg_20_100 table-cell align-middle p-reg_12">
                    <p class="text-xs_l_reg text-reg_gray_60">Статус:</p>
                    <p class="text-xs_l_reg text-reg_black">{{order['status']['name']}} <span class="text-reg_gray_60" v-if="order['status']['change']">({{order['status']['change']}})</span></p>
                  </div>
                  <div class="w-reg_15_100 table-cell align-middle p-reg_12">
                    <p class="text-xs_l_reg text-reg_gray_60">Сумма:</p>
                    <p class="text-xs_l_reg text-reg_black">{{order['price_format']}}</p>
                  </div>
                  <div class="w-reg_30_100 table-cell align-middle p-reg_12">
                    <p class="text-xs_l_reg text-reg_black">{{order['delivery']}}</p>
                    <p class="text-xs_l_reg text-reg_gray_60" v-if="order['store']">{{order['store']}}</p>
                  </div>
              </router-link>

            </div>
          </div>
        </div>

      </div>
    </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import OrderService from "@/common/services/order.service";
import HelperService from "@/common/services/helper.service";
import StatusIcon from "@/components/ui/status-icon";

export default {
  name: "header-search",
  components: {StatusIcon, SvgIcon},
  data: () => ({
      is_active : false,
      orders : []
  }),
  props: ["query", "show"],
  watch: {
      query : "search",
      $route: 'deactivate',
      show : 'checkShow'
  },
  methods: {
    checkShow(){
        if(this.show){
          this.activate();
          this.search();
        }
    },
    deactivate(){
        this.is_active = false;
        this.$emit("hideSearch");
    },
    activate(){
        this.$emit("showSearch");
        this.is_active = true;
    },
    async search() {
      if(!HelperService.isEmpty(this.query) && this.show){
          await OrderService.searchOrders(this.query)
            .then((response) => {
                this.orders = response.data.message.orders;
                this.is_active = true;
            })
            .catch(() => {
                this.$nextTick(() => {
                    this.is_active = false;
                });
            })
            .finally(() => {

            });
      } else {
        this.is_active = false;
      }
    },
  }
}
</script>

<style scoped>

</style>