<template>
    <div class="flex flex-row justify-between items-center">
      <ul class="px-reg_24 pb-reg_24">
        <li class="inline-flex text-xs_l_reg text-reg_gray_60" v-for="(tab) in list" v-bind:key="tab['route']" >
          <router-link :to="{ name: tab['route']}" :title="tab['title']" class="tab_label inline-flex text-sm_reg  rounded border py-reg_5 mb-reg_4  px-reg_10 mr-2 cursor-pointer hover:text-reg_white hover:bg-reg_blue"  :class="[routeName === tab['route'] ? 'router-link-active text-reg_white bg-reg_blue' : 'bg-reg_white border-reg_gray_75']">
              <span class="text text-sm_reg  text-xs_l_reg">{{tab['title']}}</span>
          </router-link>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: "deliveries-tab",
  data: () => ({
    list : [
      {'route' : 'order_evropochta_request', 'title' : 'Европочта'},
      {'route' : 'order_cdek_request', 'title' : 'СДЭК'}
    ]
  }),
  computed: {
      routeName(){
          return this.$route.name;
      }
  }
}
</script>