const ID_ORDER_CONTACT_LOCAL_ = "order_create_local_";
const ID_ORDER_DELIVERY_LOCAL_ = "order_create_delivery_local_";
const ID_ORDER_PAYMENT_LOCAL_ = "order_create_payment_local_";

export const getOrderContactLocal = () => {
    return window.localStorage.getItem(ID_ORDER_CONTACT_LOCAL_);
};

export const saveOrderContactLocal = data => {
    window.localStorage.setItem(ID_ORDER_CONTACT_LOCAL_, data);
};

export const destroyOrderContactLocal = () => {
    window.localStorage.removeItem(ID_ORDER_CONTACT_LOCAL_);
};

export const getOrderDeliveryLocal = () => {
    return window.localStorage.getItem(ID_ORDER_DELIVERY_LOCAL_);
};

export const saveOrderDeliveryLocal = data => {
    window.localStorage.setItem(ID_ORDER_DELIVERY_LOCAL_, data);
};

export const destroyOrderDeliveryLocal = () => {
    window.localStorage.removeItem(ID_ORDER_DELIVERY_LOCAL_);
};

export const getOrderPaymentLocal = () => {
    return window.localStorage.getItem(ID_ORDER_PAYMENT_LOCAL_);
};

export const saveOrderPaymentLocal = data => {
    window.localStorage.setItem(ID_ORDER_PAYMENT_LOCAL_, data);
};

export const destroyOrderPaymentLocal = () => {
    window.localStorage.removeItem(ID_ORDER_PAYMENT_LOCAL_);
};

export default {
    getOrderContactLocal, saveOrderContactLocal, destroyOrderContactLocal,
    getOrderDeliveryLocal, saveOrderDeliveryLocal, destroyOrderDeliveryLocal,
    getOrderPaymentLocal, saveOrderPaymentLocal, destroyOrderPaymentLocal,
};