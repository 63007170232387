import EmailService from "@/common/services/email.service";

import {
    FETCH_LETTER,
    UPDATE_QUANTITY_BY_HASH,
    UPDATE_DEFAULT_STATUS_BY_HASH
} from "./actions.type";

import {
    SET_LETTER,
    SET_QUANTITY_BY_HASH,
    SET_DEFAULT_STATUS_BY_HASH
} from "./mutations.type";

const state = {
    letter: {}
};

const getters = {
    letter(state) {
        return state.letter;
    },
    letterProducts(state) {
        return state.letter['products'];
    },
};

const actions = {

    async [FETCH_LETTER](context, hash) {
        const {data} = await EmailService.getLetter(hash)
        context.commit(SET_LETTER, data.letter);
        return data.order;
    },
    async [UPDATE_QUANTITY_BY_HASH](context, params) {
        const {data} = await EmailService.updateQuantityByHash(params['hash'], params['hash_product'], params['quantity']);
        context.commit(SET_QUANTITY_BY_HASH, {"key" : params['key'], "product" : data['product']});
        return data;
    },
    async [UPDATE_DEFAULT_STATUS_BY_HASH](context, params) {
        const {data} = await EmailService.updateDefaultStatusByHash(params['hash'], params['hash_product']);
        context.commit(SET_QUANTITY_BY_HASH, {"key" : params['key'], "product" : data['product']});
        return data;
    },

};

const mutations = {
    [SET_LETTER](state, letter) {
        state.letter = letter;
    },
    [SET_QUANTITY_BY_HASH](state, payload) {
        state.letter['products'].splice(payload['key'], 1, payload['product']);
    },
    [SET_DEFAULT_STATUS_BY_HASH](state, payload) {
        state.letter['products'].splice(payload['key'], 1, payload['product']);
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};