//user
export const LOGIN = "login";
export const LOGOUT = "logout";
export const SEARCH_USER_BY_PHONE = "searchUserByPhone";
export const UPDATE_MENU_STATUS = "updateMenuStatus";
export const CREATE = "create";
export const GET_USER_INFO = "getUserInfo";


//email
export const FETCH_LETTER = "letter";
export const UPDATE_QUANTITY_BY_HASH = "updateQuantityByHash";
export const UPDATE_DEFAULT_STATUS_BY_HASH = "updateDefaultStatusByHash";

//order
export const GET_ORDER_CREATE_CONTACT = "getOrderCreateContact";
export const UPDATE_DB_ORDER_CREATE_CONTACT = "updateDbOrderCreateContact";
export const UPDATE_ORDER_CREATE_CONTACT_LOCAL = "updateOrderCreateContactLocal";
export const GET_ORDER_SELECTS = "getOrderSelects";
export const GET_CITIES = "getCities";
export const GET_LOCATIONS = "getLocations";
export const GET_ORDER_CREATE_DELIVERY = "getOrderCreateDelivery";
export const UPDATE_ORDER_CREATE_DELIVERY_LOCAL = "updateOrderCreateDeliveryLocal";
export const GET_ORDER = "getOrder";

export const DELETE_COUPON = "deleteCoupon";

export const UPDATE_ORDER_UPDATE_CONTACT = "updateOrderUpdateContact";
export const UPDATE_ORDER_CANCEL_CONTACT = "updateOrderCancelContact";
export const UPDATE_ORDER_UPDATE_CONTACT_B2B = "updateOrderUpdateContactB2b";
export const UPDATE_ORDER_CANCEL_CONTACT_B2B = "updateOrderCancelContactB2b";
export const UPDATE_ORDER_UPDATE_PRODUCTS = "updateOrderUpdateProducts";
export const UPDATE_ORDER_CANCEL_PRODUCTS = "updateOrderCancelProducts";
export const UPDATE_ORDER_QUANTITY_PRODUCT = "updateOrderQuantityProduct";

export const UPDATE_DB_ORDER_CREATE_DELIVERY = "updateDbOrderCreateDelivery";
export const UPDATE_ORDER_CANCEL_DELIVERY = "updateOrderCancelDelivery";

export const FILL_CONTACT = "fillContact";
export const ADD_PRODUCT = "addProduct";

export const GET_ORDER_ADDITIONAL_INFO = "getOrderAdditionalInfo";
export const UPDATE_ORDER_CREATE_PAYMENT_LOCAL = "updateOrderCreatePaymentLocal";
export const GET_ORDER_CREATE_PAYMENT = "getOrderCreatePayment";

export const UPDATE_ORDER_CANCEL_PAYMENT = "updateOrderCancelPayment";
export const UPDATE_DB_ORDER_CREATE_PAYMENT = "updateDbOrderCreatePayment";

export const CLEAR_ORDER_LOCAL = "clearOrderLocal";

export const UPDATE_ORDER_CANCEL_COMMENT = "updateOrderCancelComment";
export const UPDATE_ORDER_CREATE_COMMENT_LOCAL = "updateOrderCreateCommentLocal";
export const GET_ORDER_CREATE_COMMENT = "getOrderCreateComment";
export const UPDATE_ORDER_UPDATE_COMMENT = "updateOrderUpdateComment";
export const UPDATE_ORDER_UPDATE_COMMENT_FOR_LOGIST = "updateOrderUpdateCommentForLogist";

export const UPDATE_DB_ORDER_CREATE_COMMENT = "updateDbOrderCreateComment";











