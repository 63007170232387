<template>
  <div>
      <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"  @click="hide()"></div>

      <div class="fixed z-100 top-0 right-0 md:w-reg_420 w-reg_100_100 h-auto min-h-screen bg-reg_white">

        <div class="relative p-reg_24">
            <h2 class="text-lg_reg text-reg_black font-semibold">Уведомления</h2>

            <button class="text-xs_l_reg text-reg_blue font-medium mt-reg_8" v-if="loaded && list['items'].length > 0" @click="deactivateAll" >Отметить всё как прочитанное</button>

            <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_24 h-reg_24 mr-reg_24 mt-reg_24" @click="hide()">
                <svg-icon name="icon__close" class="icon__close w-reg_24 h-reg_24 fill-reg_gray_60" />
            </button>
        </div>

        <div class="border-t border-reg_gray_75"  :class="[!loaded ? 'block-spinner_page top' : '']" >
          <div class="h-events-bottom overflow-hidden pr-reg_4" v-if="loaded">

            <div class="block-not_scroll overflow-y-scroll h-full flex flex-col pr-reg_4">

              <div v-for="(item, key) in list['items']" v-bind:key="key" class="flex flex-col space-y-[5px] border-b border-r border-reg_gray_75 py-reg_16 px-reg_20" :class="[!item['active'] ? 'bg-reg_blue_5' : '']">

                <div class="flex flex-row justify-between">
                    <p class="text-xs_l_reg text-reg_black font-semibold">
                      Заказ <router-link class="text-reg_blue text-xs_l_reg" :to="{ name: 'order_edit', params: {id: item['order_id']}}">{{item['order_id']}}</router-link>: {{item['type']}}
                    </p>

                    <button v-if="item['active']" @click="deactivateEvent(item)">
                      <svg-icon  name="icon__check" class="icon__check fill-reg_gray_25 w-reg_18 h-reg_18 cursor-pointer z-3" />
                    </button>
                    <button v-else class="cursor-default">
                      <svg-icon  name="icon__check" class="icon__check fill-reg_green w-reg_18 h-reg_18" />
                    </button>

                </div>

                <p class="text-xs_l_reg text-reg_gray_50">{{item['date_insert_log']}}</p>
                <p class="text-xs_l_reg text-reg_black" v-if="item['message']">{{item['message']}}</p>

                <a :href="item['product']['link']" target="_blank" class="text-xs_l_reg text-reg_blue" v-if="item['product']">{{item['product']['name']}}</a>
                <p class="text-xs_l_reg text-reg_black" v-if="item['product']">Код товара: <b>{{item['product']['code']}}</b></p>
              </div>

              <div class="py-reg_16 px-reg_20 pb-reg_32" v-if="list['is_pagination']">
                <button class="text-xs_l_reg text-reg_blue font-medium" @click="getItems(list['page'] + 1, false)">Загрузить больше уведомлений</button>
              </div>


              <p class="py-reg_16 px-reg_20 text-xs_l_reg text-reg_black" v-if="list['items'].length <=0">Уведомления отсутствуют.</p>

            </div>

          </div>
        </div>


      </div>
  </div>

</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import UserService from "@/common/services/user.service";

export default {
name: "notification",
  components: {SvgIcon},
  data: () => ({
      loaded : true,
      list : {
          items : [],
          page : 1,
          is_pagination : false
      }

  }),
  methods : {
    hide(){
        this.$emit('hide');
    },
    async getItems(page, loading = true) {

      if(loading)
        this.loaded = false;

        this.list['page'] = page;

          await UserService.getEvents(page)
          .then((response) => {
              this.list['items']  = [...this.list['items'], ...response['data']['items']];
              this.list['is_pagination'] = response['data']['is_pagination'];
          }).catch(() => {

          }).finally(() => {
              if(loading)
                this.loaded = true;
          });
    },
    async deactivateAll(){
        this.$root.$emit("notificationHide");

        await UserService.deactivateEventAll()
            .then(() => {

              this.list['items'].forEach((item) =>  {
                  item['active'] = false;
              });
            }).catch((response) => {
              this.$root.$emit("notificationShow", {title : "Ошибка деактивации уведомлений", type : "error", "text" : response.data.message.errors_format.all});
            });
    },
    async deactivateEvent(item){

      this.$root.$emit("notificationHide");

      await UserService.deactivateEvent(item['id'])
          .then(() => {
            item['active'] = false;
          }).catch((response) => {
              this.$root.$emit("notificationShow", {title : "Ошибка деактивации уведомления", type : "error", "text" : response.data.message.errors_format.all});
          });
    },


  },
  mounted() {
      this.getItems(1);
  }

}
</script>

<style scoped>
  body{
      overflow-y: hidden;
  }
</style>