import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";

const B2bService = {
    getShipments(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/b2b/shipments", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getItem(id){
        return ApiService.get(API_OMS_URL+"/b2b/shipments/" + id, {
            headers: ApiService.getHeader(),
        });
    },
    getReportMotivationCollect(location = null, filter = []){
        return ApiService.get(API_OMS_URL+"/b2b/reports/collect/motivation", {
            headers: ApiService.getHeader(),
            params : {filter : filter, location_id : location}
        });
    },
    getReportMotivationCollectXlsx(filter = [], location_id = 0){
        return ApiService.post(API_OMS_URL+'/b2b/reports/collect/motivation/xlsx', {filter : filter, location_id : location_id}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    fakeSend1c(id, products){
        return ApiService.put(API_OMS_URL+"/b2b/shipment/"+id+"/fake-send-1c", products, {
            headers: ApiService.getHeader(),
        })
    },
}

export default B2bService;