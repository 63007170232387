const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

const ID_TOKEN_KEY_REFRESH = "id_token_refresh";

export const getTokenRefresh = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY_REFRESH);
};

export const saveTokenRefresh = token => {
    window.localStorage.setItem(ID_TOKEN_KEY_REFRESH, token);
};

export const destroyTokenRefresh = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY_REFRESH);
};

export default { getToken, saveToken, destroyToken, getTokenRefresh, saveTokenRefresh, destroyTokenRefresh};