import ApiService from "@/common/api.service";
import {API_OMS_URL} from "@/common/config";

const ShipmentService = {
    getList(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getMoveList(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+'/shipment/move/list', {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        })
    },
    getAll(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/shipment/all", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getAllMoving(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/shipment/moving/all", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getItem(id){
        return ApiService.get(API_OMS_URL+"/shipment/" + id, {
            headers: ApiService.getHeader(),
        });
    },
    getItemEmails(id){
        return ApiService.get(API_OMS_URL+"/shipment/" + id + '/emails', {
            headers: ApiService.getHeader(),
        });
    },
    getConsolidation(store_id = 0, filter = {}, page = 1, ignore_manager = false){
        return ApiService.get(API_OMS_URL+"/shipment/consolidation/"+store_id+"/all", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page, ignore_manager : ignore_manager}
        });
    },
    getHeadRetail(store_id, filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/shipment/head-retail/"+store_id+"/all", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    updateStatusMovingShipment(id, status){
        return ApiService.put(API_OMS_URL+"/shipment/"+id+"/moving/status", {status : status}, {
            headers: ApiService.getHeader(),
        })
    },
    updateDateMovingShipment(id, date){
        return ApiService.put(API_OMS_URL+"/shipment/"+id+"/moving/date", {date : date}, {
            headers: ApiService.getHeader(),
        })
    },
    getReportMotivationCollect(location = null, filter = []){
        return ApiService.get(API_OMS_URL+"/shipment/reports/collect/motivation", {
            headers: ApiService.getHeader(),
            params : {filter : filter, location_id : location}
        });
    },
    getReportMotivationCollectByDepartment(filter = []){
        return ApiService.get(API_OMS_URL+"/shipment/reports/collect/motivation/by/department", {
            headers: ApiService.getHeader(),
            params : {filter : filter}
        });
    },
    getReportCollectUserTime(filter = [], page = 1){
        return ApiService.get(API_OMS_URL+"/shipment/reports/collect/user/time", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getReportCollectUserTimeXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/user/time/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentCreateXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/create/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentInProcessXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/in_process/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentInProcessAllXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/in_process/all/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentInProcessAllPriceXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/in_process/all/price/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentCancelXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/cancel/all/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentNotFoundXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/not_found/all/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectChannelXlsx(filter = [], location_id = 0){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/channel/xlsx', {filter : filter, location_id : location_id}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectXlsx(filter = [], location_id = 0){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/xlsx', {filter : filter, location_id : location_id}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentPriceXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/price/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    getReportMotivationCollectByDepartmentCreatePriceXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/reports/collect/motivation/by/department/create/price/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
    fakeSend1c(id, products){
        return ApiService.put(API_OMS_URL+"/shipment/"+id+"/fake-send-1c", products, {
            headers: ApiService.getHeader(),
        })
    },
    reSendCollect(id, products){
        return ApiService.put(API_OMS_URL+"/shipment/"+id+"/re-send", products, {
            headers: ApiService.getHeader(),
        })
    },
    getReturn(filter = {}, page = 1){
        return ApiService.get(API_OMS_URL+"/shipment/return", {
            headers: ApiService.getHeader(),
            params : {filter : filter, page : page}
        });
    },
    getReturnXlsx(filter = []){
        return ApiService.post(API_OMS_URL+'/shipment/return/xlsx', {filter : filter}, {
            headers: ApiService.getHeader(),
            responseType : 'blob'
        })
    },
}

export default ShipmentService;