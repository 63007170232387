<template>
  <div>
      <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"></div>
      <div class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center">
        <div class="modal-block w-reg_1000 rounded bg-reg_white p-reg_24" v-if="loaded">
          <div class="modal-block_header relative">
            <h2 class="modal-block_title text-lg_l_reg text-reg_black font-semibold">Состав отгрузки № {{shipment['id_1c']}}</h2>
            <p class="text-xs_reg text-reg_black_75 mt-reg_4">Дата создания: {{shipment['date_format']}}</p>

            <button class="text-xs_reg  mt-reg_4  mr-reg_12" :class="[is_edit ? 'text-reg_black' : 'text-reg_blue cursor-pointer']" @click="edit" v-if="isAdmin && shipment['status_id'] === 'N'" type="button">Обработать как 1с</button>

            <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mt-reg_10_m mr-reg_10_m" @click="hidePopup" type="button">
                <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75" />
            </button>

          </div>
          <div class="modal-block_body mt-reg_24">

            <div class="h-reg_320 overflow-hidden"  style="max-height: 65vh; overflow-y: auto;">
              <div class="block-scroll h-full overflow-auto pr-reg_15">
                <div class="table w-full table-auto border-collapse px-reg_24 pb-reg_24"  v-if="shipment['products'].length > 0">
                  <div class="table-header-group">
                    <div class="table-row border-y border-reg_gray_10">
                      <div class="table-cell align-middle p-reg_12 ">
                        <p class="text-xs_l_reg text-reg_black font-semibold">№</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                        <p class="text-xs_l_reg text-reg_black font-semibold">Товар</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                        <p class="text-xs_l_reg text-reg_black font-semibold">Штрихкоды</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                        <p class="text-xs_l_reg text-reg_black font-semibold">Факт (ответ 1С)</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                        <p class="text-xs_l_reg text-reg_black font-semibold">План</p>
                      </div>

                    </div>
                  </div>
                  <div class="table-row-group" v-for="(product, key) in shipment['products']" v-bind:key="key">
                      <div class="table-row border-b border-reg_gray_10">
                      <div class="table-cell align-middle p-reg_12 ">
                          <p class="text-xs_l_reg text-reg_gray_60">{{key+1}}</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                        <div class="flex flex-row items-center">
                          <div class="min-w-reg_40 w-reg_40">
                            <img v-if="product['picture']" :src="product['picture']" class="">
                          </div>
                          <div class="ml-reg_16">
                            <div class="text-xs_l_reg text-reg_black font-medium">{{product['name']}}</div>
                            <div class="text-xs_l_reg text-reg_black_75 mt-reg_4">Код товара: {{product['xml_id']}}</div>
                            <div class="text-xs_l_reg text-reg_black_75">Склад: {{product['store_to']}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                          <p class="text-xs_l_reg text-reg_black">{{product['barcodes']}}</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12">
                          <ui-input
                              v-if="is_edit"
                              label=""
                              type="number"
                              :name_custom="'product_'+product['id']+'_'+product['store_id_to']"
                              :name="'product_'+product['id']+'_'+product['store_id_to']"
                              :value="product['real']"
                              v-model.number="product['real']"
                              class_block=""
                              class_label="relative text-sm_reg text-reg_black font-semibold"
                              class_input="block border border-reg_gray_75 text-reg_black_75 w-full rounded text-sm_reg py-reg_8 px-reg_13"
                              placeholder=""
                              :required="true"
                              style="width: 80px;"
                              :max="product['plan']"
                              :step="product['step']"
                              min="product['min']"
                          />
                          <p v-else class="text-xs_l_reg text-reg_black">{{product['real']}} шт.</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 ">
                          <p class="text-xs_l_reg text-reg_black">{{product['plan']}} шт.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="table w-full table-auto border-collapse text-reg_red" style="margin: 10px 0px 10px 0px;">Товары отсутствуют</div>
                </div>

                <button class="inline-flex rounded bg-reg_blue hover:bg-reg_blue_100 font-medium text-reg_white text-sm_reg py-reg_8 px-reg_20 mt-reg_10 mr-reg_12"  v-if="is_edit" @click="fakeSend1c">Обработать</button>

              </div>
            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import B2bService from "@/common/services/b2b.service";
import {mapGetters} from "vuex";
import HelperService from "@/common/services/helper.service";
import UiInput from "@/components/ui/form/elements/input.vue";

export default {
  name: "shipment-detail-b2b",
  components: {UiInput, SvgIcon},
  data () {
      return {
          shipment: {},
          loaded : false,
          is_edit: false
      }
  },
  computed: {
    ...mapGetters(["isAdmin"])
  },
  created(){
      this.getShipment();
  },
  watch: {
    id : "getShipment"
  },
  props: {
      id : {}
  },
  methods : {
      async getShipment() {
        await B2bService.getItem(this.id)
            .then((response) => {
                this.shipment = response.data.shipment;
            }).catch(() => {
              //todo
            })
            .finally(() => {
                this.loaded = true;
            });
      },
      hidePopup(){
          this.$root.$emit("shipmentDetailB2bHide");
      },
      edit(){
        this.is_edit = true;
        this.getShipment(false);
      },
      async fakeSend1c() {
        await B2bService.fakeSend1c(this.id, {products: this.shipment['products']})
            .then(() => {
              this.getShipment();
            })
            .catch((response) => {
              this.$nextTick(() => {
                if (!HelperService.isEmpty(response.data.message.errors_format))
                  this.$root.$emit("notificationShow", {
                    title: "Ошибка обновления отгрузки",
                    type: "error",
                    "text": response.data.message.errors_format.all
                  });
              });
            })
            .finally(() => {
              this.is_edit = false;
            });
      }
  }
}
</script>

<style scoped>

</style>