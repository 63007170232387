<template>
  <div class="content_left-menu h-auto min-h-screen bg-reg_white p-reg_12 border-r border-reg_gray_75 w-max">

    <button class="btn_toggle-menu p-reg_8" @click="toggleMenu">
        <svg-icon class="icon__toggle-menu w-reg_24 h-reg_24 fill-reg_gray_50" name="icon__toggle-menu" />
    </button>

    <ul class="menu-left  mt-10 w-reg_40" >

      <li class="menu-left_item" v-if="isColCenter || isTablet || isProblem">
          <router-link :to="{ name: 'order_create' }" :title="'Контакт-центр'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_create'}">
            <svg-icon class="icon__tel w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__tel" />
            <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Контакт-центр</span>
          </router-link>
      </li>

      <li class="menu-left_item" v-if="isColCenter || isProblem || isConsolidation">
        <router-link :to="{ name: 'order_all' }" :title="'Все заказы'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_all'}">
            <svg-icon class="icon__tel w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__cart" />
            <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Все заказы</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isColCenter || isProblem || isTablet">
        <router-link :to="{ name: 'order_user' }" :title="'Мои заказы'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_user'}">
          <svg-icon class="icon__tel w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__cart" />
          <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Мои заказы</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isColCenter || isProblem">
        <router-link :to="{ name: 'order_installment' }" :title="'Скрепка'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_installment'}">
          <svg-icon class="icon__a-paperclip w-reg_18 h-reg_18" name="icon__a-paperclip" />
          <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Скрепка (банки)</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isProblem">
          <router-link :to="{ name: 'order_problems' }" :title="'Проблемы заказов'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_problems'}">
              <svg-icon class="icon__exclamation-triangle w-reg_18 h-reg_18 fill-reg_gray_50" name="icon__exclamation-triangle" />
              <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Проблемы заказов</span>
          </router-link>
      </li>

      <li class="menu-left_item" v-if="isOnOrder">
          <router-link :to="{ name: 'on_order_products' }" :title="'Проблемы заказов'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'on_order_products'}">
            <svg-icon class="icon__clock w-reg_18 h-reg_18 fill-reg_gray_60" name="icon__clock" />
            <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Товары под заказ</span>
          </router-link>
      </li>

      <li class="menu-left_item" v-if="isAdmin || isOnOrder">
        <router-link :to="{ name: 'sales' }" :title="'Акции'" class="link flex flex-row items-center p-reg_11 tooltip"  :class="{active: this.$route.name === 'sales'}">
          <svg-icon class="icon__head_2 w-reg_18 h-reg_18 fill-transparent" name="icon__sales" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Акции</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isCollect">
        <router-link :to="{ name: 'order_collect_actual' }" :title="'Задачи на сборку'" class="link flex flex-row items-center p-reg_11 tooltip"  :class="{active: this.$route.meta['section'] === 'order_collect'}">
          <svg-icon class="icon__collect w-reg_18 h-reg_18 fill-transparent stroke-reg_gray_50" name="icon__collect" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Задачи на сборку</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isConsolidation">
        <router-link :to="{ name: 'order_consolidation' }" :title="'Консолидация'" class="link flex flex-row items-center p-reg_11 tooltip" v-if="isConsolidation" :class="{active: this.$route.name === 'order_consolidation'}">
          <svg-icon class="icon__cube w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__cube" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Консолидация</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isProblem || isAdmin">
        <router-link :to="{ name: 'order_consolidation_all' }" :title="'Консолидация (все)'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_consolidation_all'}">
          <svg-icon class="icon__cube w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__cube" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Консолидация (все)</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isLogistic">
        <router-link :to="{ name: 'order_logistic' }" :title="'Логистика'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.meta['section'] === 'logistic'}">
          <svg-icon class="icon__delivery w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__delivery" />
          <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Логистика</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isProblem">
        <router-link :to="{ name: 'order_shipments_move_list' }" :title="'Перемещения'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_shipments_move_list'}">
          <svg-icon class="icon__pull-request w-reg_18 h-reg_18 fill-transparent" name="icon__pull-request" />
          <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Перемещения</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isProblem">
        <router-link :to="{ name: 'order_shipments_list' }" :title="'Отгрузки по заказам'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_shipments_list'}">
          <svg-icon class="icon__shipments-orders w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__shipments-orders" />
          <span class="tooltip-text text-sm_reg text-reg_black pl-reg_15">Отгрузки по заказам</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isHead">
        <router-link :to="{ name: 'order_head_retail' }" :title="'Начальник отдела'" class="link flex flex-row items-center p-reg_11 tooltip"  :class="{active: this.$route.name === 'order_head_retail'}">
          <svg-icon class="icon__head w-reg_18 h-reg_18 fill-transparent" name="icon__head" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Начальник отдела</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isConsolidation || isHead || isAdmin">
        <router-link :to="{ name: 'order_evropochta_request' }" :title="'Заявки'" class="link flex flex-row items-center p-reg_11 tooltip" :class="{active: this.$route.name === 'order_evropochta_request' || this.$route.name === 'order_cdek_request'}">
            <svg-icon class="icon__pull-request w-reg_18 h-reg_18 fill-transparent" name="icon__pull-request" />
            <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Заявки</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isHead">
        <router-link :to="{ name: 'personal_employees' }" :title="'Сотрудники отдела'" class="link flex flex-row items-center p-reg_11 tooltip"  :class="{active: this.$route.name === 'personal_employees'}">
          <svg-icon class="icon__head_2 w-reg_18 h-reg_18 fill-transparent" name="icon__head_2" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Сотрудники отдела</span>
        </router-link>
      </li>

      <li class="menu-left_item" v-if="isReport">
        <router-link :to="{ name: 'reports'}" :title="'Отчеты'" class="link flex flex-row items-center p-reg_11 tooltip"  :class="{active: this.$route.meta['section'] === 'reports'}">
          <svg-icon class="icon__report w-reg_18 h-reg_18 fill-transparent" name="icon__report" />
          <span class="tooltip-text text-sm_reg pl-reg_15 text-reg_blue">Отчеты</span>
        </router-link>
      </li>

    </ul>

  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import {mapGetters} from "vuex";
export default {
  name: "leftmenu",
  components: {SvgIcon},
  props: {
    status: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters(["isTablet", "isColCenter", "isLogistic", "isProblem", "isConsolidation", "isHead", "isAdmin", "isCollect", "isReport", "isOnOrder"]),
  },
  methods: {
    toggleMenu() {

    },
  }
}
</script>

<style scoped>

</style>