<script>
import AbstractPopup from "@/components/ui/abstractpopup";
import PopupService from "@/common/services/popup.service";

export default {
  name: "user-orders",
  data () {
      return {
          title : !this.only_active ? "Все заказы клиента" : "Активные заказы клиента",
          items : [],
          name : "user_orders",
      }
  },
  mixins : [AbstractPopup],
  props: {
      order_id : {
          type : Number,
          require : true
      },
      only_active : {
          type : Boolean,
          require : false
      }
  },
  watch: {
      $route: 'hidePopup',
  },
  methods : {
      async getItems(){
        this.beforeGetItems();
        await PopupService.getTable("user_orders", this.order_id, "", {only_active : this.only_active})
            .then((response) => {
                this.setData(response.data);
            }).catch(() => {
              //todo
            })
            .finally(() => {
                this.afterGetItems();
            });
      },
      hidePopup(){
          this.$root.$emit("_popup_user_order_hide");
      }
  }
}
</script>
