import store from '@/store'

export default function (to, from, next) {

    let isAuthenticated = store.getters.isAuthenticated;
    
    if (isAuthenticated) {
        next()
    } else {
        next('/auth')
    }
}