<template>
  <div :class="[class_block]">
    <div class="text-reg_black text-xs_l_reg">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  name: "notFound",
  props: {
    class_block : {
      type : String,
      default : "p-reg_24"
    },
    text: {
        required: false,
    }
  },
}
</script>

<style scoped>

</style>