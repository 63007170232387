<template>
  <div :class="[!loaded ? 'block-spinner_page' : '']">
    <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"></div>

    <div class="fixed z-100 top-28 left-0 right-0 mx-auto flex justify-center items-center"  >
      <div class="modal-block w-reg_630 rounded bg-reg_white" v-if="loaded">
        <div class="modal-block_header relative p-reg_24" :class="[!loadedPdf ? 'block-spinner_page' : '']">
          <h2 class="modal-block_title text-lg_reg text-reg_black font-semibold">Задача на поиск товаров</h2>

          <p class="mt-reg_4 text-sm_reg text-reg_black_75">
              <span>Отгрузка № {{letter['shipment_code']}}</span>
              <span class="px-reg_5">⁄</span>
              <span>Дата: {{letter['date']}}</span>
          </p>

          <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mr-reg_12 mt-reg_12"  @click="hidePopup">
            <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75"/>
          </button>

          <div class="flex flex-row space-x-[12px] mt-reg_12"  v-if="letter['email_status'] !== 'C'">
            <div class="grow relative">

              <div v-if="!employee && letter['email_status'] === 'F'" class="block-pink flex flex-row items-center rounded border border-reg_red_25 bg-reg_red_10 py-reg_10 px-reg_15">
                <svg-icon name="icon__alert-circle" class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" />
                <p class="pl-reg_10 text-sm_m_reg text-reg_black">Задача уже выполнена, проставить комплектовщика нельзя.</p>
              </div>
              <div v-else-if="!employee" class="block-pink flex flex-row items-center rounded border border-reg_red_25 bg-reg_red_10 py-reg_10 px-reg_15">
                  <svg-icon name="icon__alert-circle" class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" />
                  <p class="pl-reg_10 text-sm_m_reg text-reg_black">Выберите сотрудника для отображения товаров</p>
              </div>
              <div v-else>
                <p class="text-sm_reg text-reg_black">Комплектовщик:</p>
                <p class="text-sm_reg text-reg_black"><b>{{employee['title']}}</b></p>
              </div>

            </div>

          </div>

          <div class="flex flex-row space-x-[12px] mt-reg_12"  v-if="letter['email_status'] !== 'C'">
            <div class="" v-if="employee">

              <button type="button" @click="emailPdf();" class="font-medium text-reg_blue text-sm_reg mr-reg_12">
                <svg-icon name="icon__printer" class="icon__printer inline-flex w-reg_16 h-reg_16 mr-reg_8 fill-reg_black opacity-50" />
                Распечатать без картинок
              </button>

              <button type="button" @click="emailPdf(true);" class="font-medium text-reg_blue text-sm_reg">
                <svg-icon name="icon__printer" class="icon__printer inline-flex w-reg_16 h-reg_16 mr-reg_8 fill-reg_black opacity-50" />
                Распечатать с картинками
              </button>
            </div>
          </div>

          <div class="block-pink flex flex-row items-center mt-reg_12 rounded border border-reg_red_25 bg-reg_red_10 py-reg_10 px-reg_15" v-if="letter['email_status'] === 'C'">
              <svg-icon class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" name="icon__alert-circle" />
              <p class="pl-reg_10 text-sm_m_reg text-reg_black">Отгрузка отменена. Сборка неактуальна.</p>
          </div>
        </div>

        <div class="modal-block_body mt-reg_12 border-t border-reg_gray_25" v-if="loadedLetter"  style="max-height: 50vh;overflow-y: scroll;">
          <div class="mt-reg_8" v-if="employee || letter['email_status'] === 'C' || letter['email_status'] === 'F'">

            <div v-for="(product, key) in letter['products']" v-bind:key="key" class="flex flex-row space-x-[24px] py-reg_15 px-reg_24" :class="key>=1 ? 'border-t border-reg_gray_25' : ''">
              
              <div class="flex-none image-block-collect w-reg_90 h-reg_110">
                  <img :src="product['picture_src']" />
              </div>
              <div class="">

                  <p class="text-sm_reg text-reg_black font-medium">{{product['name']}}</p>
                  <p class="mt-reg_10 text-sm_reg text-reg_black font-medium">Найти: {{product['quantity']}} {{product['measure']}}</p>
                  <p class="mt-reg_10 text-xs_l_reg text-reg_black_75">Код товара: {{product['xml_code']}}</p>
                  <p class="mt-reg_2 text-xs_l_reg text-reg_black_75">Штрихкоды: {{product['barcodes']}}</p>
                  <p class="mt-reg_2 text-xs_l_reg text-reg_black_75">SISKod: {{product['sis_code']}}</p>

                  <div v-if="!letter['new_logic']">
                    <div v-if="letter['email_status'] !== 'C'">
                        <div class="flex flex-row space-x-[24px] mt-reg_24" v-if="product['status'] === 'N'" >

                            <div class="w-1/3">
                                <p class="text-sm_m_reg text-reg_black font-medium">Нашёл все товары:</p>
                                <input type="number" min="0" :max="product['quantity']" disabled="" class="w-full mt-reg_8 p-reg_8 rounded bg-reg_white border border-reg_gray_25" :value="product['quantity']">
                                <a href="#" @click="updateQuantity(key, product['quantity'], product)" class="bg-reg_green block no-underline rounded mt-reg_8 py-reg_10 text-center text-sm_reg text-reg_white font-medium">Подтвердить</a>
                            </div>

                            <div class="w-1/3">
                                <p class="text-sm_m_reg text-reg_black font-medium">Нашёл только:</p>
                                <input type="number" min="0" :max="product['quantity']" @input="product['quantity_real'] = check(product['quantity'], product['quantity_real'])" v-model.number="product['quantity_real']" class="w-full mt-reg_8 p-reg_8 rounded bg-reg_white border border-reg_gray_25">
                                <a href="#" @click="updateQuantity(key, product['quantity_real'], product)" class="bg-reg_orange block no-underline rounded mt-reg_8 py-reg_10 text-center text-sm_reg text-reg_white font-medium">Подтвердить</a>
                            </div>

                            <div class="w-1/3">
                                <p class="text-sm_m_reg text-reg_black font-medium">Товар не нашёл:</p>
                                <input type="number" disabled="" class="w-full mt-reg_8 p-reg_8 rounded bg-reg_white border border-reg_gray_25" value="0">
                                <a href="#"  @click="updateQuantity(key, 0, product)" class="bg-reg_red block no-underline rounded mt-reg_8 py-reg_10 text-center text-sm_reg text-reg_white font-medium">Подтвердить</a>
                            </div>

                        </div>

                        <div class="flex flex-row items-center space-x-[10px] mt-reg_24" v-else>

                          <status-icon color="red" v-if="Number(product['quantity_real']) === 0" />
                          <status-icon color="orange" v-else-if="Number(product['quantity']) !== Number(product['quantity_real'])" />
                          <status-icon color="green" v-else />

                          <p class="text-sm_m_reg text-reg_black">Результаты поиска отправлены: найдено {{product['quantity_real']}} из {{product['quantity']}} {{product['measure']}}</p>

                        </div>

                      <div class="flex flex-row items-center space-x-[10px] mt-reg_24" v-if="product['status'] === 'S'">
                          <button v-if="product['status'] === 'S'" type="button" class="inline-flex text-center py-reg_8 px-reg_15 text-xs_reg text-reg_blue font-medium bg-reg_blue_20 rounded" @click="setDefaultStatus(key, product)" >Изменить отправленный ответ</button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                      <div class="flex flex-row items-center space-x-[10px] mt-reg_24" v-if="product['status'] !== 'N'">

                        <status-icon color="red" v-if="Number(product['quantity_real']) === 0" />
                        <status-icon color="orange" v-else-if="Number(product['quantity']) !== Number(product['quantity_real'])" />
                        <status-icon color="green" v-else />

                        <p class="text-sm_m_reg text-reg_black">Результаты поиска отправлены: найдено {{product['quantity_real']}} из {{product['quantity']}} {{product['measure']}}</p>

                      </div>
                  </div>



                  <div class="flex flex-row" v-if="errors[key]">
                      <p class="text-sm_m_reg  text-base_reg text-reg_red">{{errors[key]}}</p>
                  </div>
              </div>
            </div>

          </div>

          <div class="py-reg_20 px-reg_24" v-else>

            <div class="flex flex-row justify-between items-center py-reg_16" v-if="letter['email_status'] !== 'C' && letter['email_status'] !== 'F'" >
              <div class="flex flex-wrap items-center">
                  <button @click="selectedEmployee(employeeItem['value'])" v-for="(employeeItem, k) in this.letter['employees']" v-bind:key="k" :class="[employeeItem['value'] == employee ? 'text-reg_white bg-reg_blue' : 'text-reg_blue bg-reg_blue_10']" class="text-sm_reg  hover:text-reg_white hover:bg-reg_blue mr-reg_8 mb-reg_12 py-reg_10 px-reg_20 rounded">
                    {{employeeItem['title']}}
                  </button>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>

import SvgIcon from "@/components/ui/svgicon";
import printJS from "print-js";
import CollectService from "@/common/services/collect.service";
import StatusIcon from "@/components/ui/status-icon";

export default {
  name: "collectPopup",
  components: {StatusIcon, SvgIcon},
  data () {
      return {
          shipment_emails: [],
          loaded : true,
          loadedLetter : true,
          loadedPdf : true,
          errors : [],
          letter : {},
          employee : 0
      }
  },
  created(){
      this.fetchLetter();
  },
  props: {
      hash : {}
  },
  methods : {
      check(plan = 0 , fact = 0){

        fact = Number(fact);
        if(fact<0)
          fact = 0;

        fact = Number(fact.toFixed(3));

        if(fact > plan)
          fact = plan;

        return fact;
      },
      setDefaultValues(){
        this.letter['employees'].forEach((employeeItem) =>  {
            if(Number(this.letter['employee_id']) === Number(employeeItem['value'])){
                this.employee = employeeItem;
            }
        });
      },
      selectedEmployee(employeeId){
          CollectService.setEmployeeIdByHash(this.hash, employeeId).finally(() => {
              this.fetchLetter(true);
          });
      },
      async fetchLetter(update = false) {

        if(update)
          this.loadedLetter = false;
        else
          this.loaded = false;

          await CollectService.getItemByHash(this.hash).then((response) => {
              this.letter = response['data']['letter'];
              this.setDefaultValues();
          }).catch(() => {
            //todo
          }).finally(() => {
              setTimeout(() => {

                if(update)
                  this.loadedLetter = true;
                else
                  this.loaded = true;

              }, 250);
          });
      },
      async updateQuantity(key = 0, quantity = 0, product){

          this.errors = [];
          let params = {'key' : key, 'hash' : product['hash'], 'hash_product' : product['hash_product'], 'quantity' : quantity};

          await CollectService.setQuantityByHash(params['hash'], params['hash_product'], params['quantity']).then((response) => {
              this.$set(this.letter['products'], params['key'], response['data']['product']);
          }).catch((response) => {
              let error = response.data.message['errors_format']['all'] ? response.data.message['errors_format']['all'] : response.data.message;
              this.$set(this.errors, key, error);
          });
      },
      async setDefaultStatus(key = 0, product){

          this.errors = [];
          let params = {'key' : key, 'hash' : product['hash'], 'hash_product' : product['hash_product']};

          await CollectService.setDefaultStatusByHash(params['hash'], params['hash_product']).then((response) => {
              this.$set(this.letter['products'], params['key'], response['data']['product']);
          }).catch((response) => {
              let error = response.data.message['errors_format']['all'] ? response.data.message['errors_format']['all'] : response.data.message;
              this.$set(this.errors, key, error);
          });

      },
      async emailPdf(withPictures = false){

        this.loadedPdf = false;
        await CollectService.createPdf(this.hash, withPictures)
            .then((response) => {
                printJS({printable: response.data['pdf'], type: 'pdf', base64: true});
            })
            .catch((data) => {
                this.$nextTick(() => {
                    this.$root.$emit("notificationShow", {title : "Ошибка формирования pdf файла", type : "error", "text" : data.data.message.errors_format.all});
                });
            }).finally(() => {
                setTimeout(() => {
                  this.loadedPdf = true;
            }, 250);
        });
      },
      hidePopup(){
          this.$emit("hidePopup");
          this.$root.$emit("_update_page_custom");
      },

  }
}
</script>

<style scoped>

</style>