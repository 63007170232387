import { render, staticRenderFns } from "./headermenu.vue?vue&type=template&id=8ad6fac4&scoped=true"
import script from "./headermenu.vue?vue&type=script&lang=js"
export * from "./headermenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ad6fac4",
  null
  
)

export default component.exports