<template>
  <div :class="[!loaded ? 'block-spinner_page' : '']">
    <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none"></div>

    <div class="fixed z-100 top-28 left-0 right-0 mx-auto flex justify-center items-center"  >
      <div class="modal-block w-reg_630 rounded bg-reg_white" v-if="loaded">
        <div class="modal-block_header relative p-reg_24" :class="[!loadedPdf ? 'block-spinner_page' : '']">
          <h2 class="modal-block_title text-lg_reg text-reg_black font-semibold">Задача на разукомплектацию</h2>

          <p class="mt-reg_4 text-sm_reg text-reg_black_75">
              <span>Отгрузка № {{shipment['xml_id']}}</span>
              <span class="px-reg_5">⁄</span>
              <span>Дата: {{shipment['date']}}</span>
          </p>

          <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mr-reg_12 mt-reg_12"  @click="hidePopup">
              <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75"/>
          </button>

          <div class="flex flex-row space-x-[12px] mt-reg_12"  v-if="!shipment['status']['is_cancel']">
            <div class="grow relative">

              <div v-if="!shipment['employee']['id'] && shipment['status']['is_finish']" class="block-pink flex flex-row items-center rounded border border-reg_red_25 bg-reg_red_10 py-reg_10 px-reg_15">
                  <svg-icon name="icon__alert-circle" class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" />
                  <p class="pl-reg_10 text-sm_m_reg text-reg_black">Разукомплектация уже выполнена, проставить комплектовщика нельзя.</p>
              </div>
              <div v-else-if="!shipment['employee']['id']" class="block-pink flex flex-row items-center rounded border border-reg_red_25 bg-reg_red_10 py-reg_10 px-reg_15">
                  <svg-icon name="icon__alert-circle" class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" />
                  <p class="pl-reg_10 text-sm_m_reg text-reg_black">Выберите сотрудника для отображения товаров</p>
              </div>
              <div v-else>
                <p class="text-sm_reg text-reg_black">Комплектовщик:</p>
                <p class="text-sm_reg text-reg_black"><b>{{shipment['employee']['fio']}}</b></p>
              </div>

            </div>

          </div>

          <div class="flex flex-row space-x-[12px] mt-reg_12"  v-if="!shipment['status']['is_cancel']">
            <div class="" v-if="shipment['employee']['id']">

              <button type="button" @click="pdf();" class="font-medium text-reg_blue text-sm_reg mr-reg_12">
                  <svg-icon name="icon__printer" class="icon__printer inline-flex w-reg_16 h-reg_16 mr-reg_8 fill-reg_black opacity-50" />
                  Распечатать без картинок
              </button>

              <button type="button" @click="pdf(true);" class="font-medium text-reg_blue text-sm_reg">
                  <svg-icon name="icon__printer" class="icon__printer inline-flex w-reg_16 h-reg_16 mr-reg_8 fill-reg_black opacity-50" />
                  Распечатать с картинками
              </button>
            </div>
          </div>

          <div class="block-pink flex flex-row items-center mt-reg_12 rounded border border-reg_red_25 bg-reg_red_10 py-reg_10 px-reg_15" v-if="shipment['status']['is_cancel']">
              <svg-icon class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" name="icon__alert-circle" />
              <p class="pl-reg_10 text-sm_m_reg text-reg_black">Разукомплектация отменена.</p>
          </div>

          <div class="modal-block_body mt-reg_12 border-t border-reg_gray_25"  style="max-height: 50vh;overflow-y: scroll;">

            <div class="mt-reg_8" v-if="shipment['employee']['id'] || shipment['status']['is_cancel'] || shipment['status']['is_finish']">

              <div v-for="(product, key) in shipment['products']" v-bind:key="key" class="flex flex-row space-x-[24px] py-reg_15 px-reg_24" :class="key>=1 ? 'border-t border-reg_gray_25' : ''">

                <div class="flex-none image-block-collect w-reg_90 h-reg_110">
                    <img :src="product['picture_src']" />
                </div>
                <div class="">

                  <p class="text-sm_reg text-reg_black font-medium">{{product['name']}}</p>
                  <p class="mt-reg_10 text-sm_reg text-reg_black font-medium">Найти: {{product['quantity_plan']}} {{product['measure']}}.</p>
                  <p class="mt-reg_10 text-xs_l_reg text-reg_black_75">Код товара: {{product['xml_code']}}</p>
                  <p class="mt-reg_2 text-xs_l_reg text-reg_black_75">Штрихкоды: {{product['barcodes']}}</p>
                  <p class="mt-reg_2 text-xs_l_reg text-reg_black_75">SISKod: {{product['sis_code']}}</p>

                  <div class="flex flex-row items-center space-x-[10px] mt-reg_24" v-if="shipment['status']['is_finish']">

                    <status-icon color="red" v-if="product['quantity'] === 0" />
                    <status-icon color="orange" v-else-if="product['quantity'] !== product['quantity_plan']" />
                    <status-icon color="green" v-else />

                    <p class="text-sm_m_reg text-reg_black">Результаты поиска отправлены: найдено {{product['quantity']}} из {{product['quantity_plan']}} {{product['measure']}}.</p>

                  </div>

                </div>
              </div>

            </div>

            <div v-else>
              <div class="flex flex-row justify-between items-center py-reg_16" v-if="!shipment['status']['is_cancel'] && !shipment['status']['is_finish']" >
                <div class="flex flex-wrap items-center">
                  <button @click="selectedEmployee(item['value'])" v-for="(item, k) in this.shipment['employee']['list']" v-bind:key="k" :class="[shipment['employee']['id'] === item['value'] ? 'text-reg_white bg-reg_blue' : 'text-reg_blue bg-reg_blue_10']" class="text-sm_reg  hover:text-reg_white hover:bg-reg_blue mr-reg_8 mb-reg_12 py-reg_10 px-reg_20 rounded">
                    {{item['title']}}
                  </button>
                </div>
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
  </div>
</template>

<script>

import SvgIcon from "@/components/ui/svgicon";
import CollectService from "@/common/services/collect.service";
import StatusIcon from "@/components/ui/status-icon";
import printJS from "print-js";

export default {
  name: "collectReturnPopup",
  components: {StatusIcon, SvgIcon},
  data () {
      return {
          loaded : true,
          loadedPdf : true,
          shipment : {},
      }
  },
  created(){
      this.getShipment();
  },
  props: {
      item_id : {}
  },
  methods : {
      selectedEmployee(employeeId){
          CollectService.setEmployeeReturn(this.item_id, employeeId).finally(() => {
              this.getShipment(true);
          });
      },
      async getShipment(update = false) {

        if(update)
          this.loadedLetter = false;
        else
          this.loaded = false;

          await CollectService.getItemReturn(this.item_id).then((response) => {
              this.shipment = response['data']['collect'];
          }).finally(() => {
            if(update)
              this.loadedLetter = true;
            else
              this.loaded = true;
          });
      },
      hidePopup(){
          this.$root.$emit("shipmentCollectReturnHide");
          this.$root.$emit("_update_page_custom");
      },
      async pdf(withPictures = false){

        this.loadedPdf = false;
        await CollectService.pdfReturn(this.item_id, withPictures)
            .then((response) => {
                printJS({printable: response.data['pdf'], type: 'pdf', base64: true});
            })
            .catch((data) => {
              this.$nextTick(() => {
                  this.$root.$emit("notificationShow", {title : "Ошибка формирования pdf файла", type : "error", "text" : data.data.message.errors_format.all});
              });
            }).finally(() => {
                setTimeout(() => {
                  this.loadedPdf = true;
                }, 250);
            });
      }

  }
}
</script>