<template>
  <div>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        @click="copyToClipboard"
        :style="iconStyle"
    >
      <title v-if="title">{{ title }}</title>
      <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
    </svg>
    <div v-if="showPopup" class="popup" :class="{ fade: fading }">{{ textPopup }}</div>
  </div>
</template>

<script>
export default {
  name: 'svgIconCopy',
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    value_input: {
      type: String,
      default: ''
    },
    maskNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      showPopup: false,
      fading: false,
      textPopup: ""
    };
  },
  computed: {
    iconPath() {
      return require(`@/assets/markup/sprite.svg`) + "#" + this.name;
    },
    iconStyle() {
      return {
        cursor: this.hover ? 'pointer' : 'default',
        fill: this.hover ? '#050505' : '#737373',
        width: '30px',
        height: '30px',
      };
    }
  },
  methods: {
    copyToClipboard() {
      const textToCopy = this.maskNumber === true ? (this.value_input).replace(/\s+/g,'') : this.value_input;
      if (textToCopy !== '' && textToCopy != null) {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
              this.textPopup = "Текст скопирован";
              this.showPopup = true;
              this.fading = false;
              setTimeout(() => {
                this.fading = true;
                setTimeout(() => {
                  this.showPopup = false;
                }, 500);
              }, 1500);
            })
            .catch(err => {
              console.error('Ошибка при копировании:', err);
            });
      }
    }
  }
};
</script>

<style scoped>
.popup {
  position: absolute;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-40%, 100%);
  z-index: 10;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  padding: 13px;
}
.popup.fade {
  opacity: 0;
}
</style>