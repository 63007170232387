<template>
    <div class="fixed z-10 bottom-4 right-4" style="z-index: 111;">
      <div class="live-toast w-reg_380 p-reg_12 border rounded" :class="[type === 'info' ? 'border-reg_gray_75 bg-black' : '', type === 'error' ? 'border-reg_red bg-reg_red' : '']">
        <div class="flex flex-row items-center justify-between pb-reg_8">
          <div class="flex flex-row items-center">
            <span class="w-reg_8 h-reg_8 rounded mr-reg_8"  :class="[type === 'info' ? 'bg-reg_green' : '', type === 'error' ? 'bg-reg_white' : '']"></span>
            <h2 class="text-sm_reg" :class="[type === 'info' ? 'text-reg_white' : '', type === 'error' ? 'text-reg_white' : '']">{{title}}</h2>
          </div>
          <button type="button" @click="notificationHide">
              <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18" :class="[type === 'info' ? 'fill-reg_white' : '', type === 'error' ? 'fill-reg_white' : '']" />
          </button>
        </div>
        <div class="border-t border-reg_gray_75 pt-reg_8">
          <div class="text-sm_reg"  :class="[type === 'info' ? 'text-reg_white' : '', type === 'error' ? 'text-reg_white' : '']" v-html="notification">{{notification}}</div>
        </div>
      </div>
    </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
export default {
  name: "notification",
  components: {SvgIcon},
  props : ['title', 'show', 'type', 'notification'],
  methods : {
    notificationHide(){
        this.$emit('notificationHide');
    }
  },
}
</script>

<style scoped>

</style>