<template>
  <div>
      <div class="overlay bg-reg_black_90 z-90 fixed top-0 left-0 right-0 bottom-0 transition-none" v-if="!popups.collect.show"></div>
      <div class="fixed z-100 top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center" v-if="!popups.collect.show">
        <div class="modal-block w-reg_750 rounded bg-reg_white p-reg_24" v-if="loaded">
          <div class="modal-block_header relative">
            <h2 class="modal-block_title text-lg_l_reg text-reg_black font-semibold">Задачи на сборку</h2>
            <button class="btn_close absolute flex items-center justify-center right-0 top-0 rounded-full w-reg_32 h-reg_32 mt-reg_10_m mr-reg_10_m" @click="hidePopup" type="button">
                <svg-icon name="icon__close" class="icon__close w-reg_18 h-reg_18 fill-reg_black_75" />
            </button>
          </div>
          <div class="modal-block_body mt-reg_24">

            <div class="h-reg_320 overflow-hidden"  style="max-height: 320px;overflow-y: auto;">
              <div class="block-scroll h-full overflow-auto pr-reg_15">
                <div class="table w-full table-auto border-collapse px-reg_24 pb-reg_24"  v-if="shipment_emails.length > 0">
                  <div class="table-header-group">
                    <div class="table-row border-y border-reg_gray_10">
                      <div class="table-cell align-middle p-reg_12 w-reg_5_100">
                        <p class="text-xs_l_reg text-reg_black font-semibold">№</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 w-reg_50_100">
                        <p class="text-xs_l_reg text-reg_black font-semibold">Статус</p>
                      </div>
                      <div class="table-cell align-middle p-reg_12 w-reg_20_100">
                        <p class="text-xs_l_reg text-reg_black font-semibold"></p>
                      </div>
                    </div>
                  </div>
                  <div class="table-row-group" v-for="(email) in shipment_emails" v-bind:key="email['id']">
                      <div class="table-row border-b border-reg_gray_10">
                          <div class="table-cell align-middle p-reg_12 w-reg_5_100">
                              <p class="text-xs_l_reg text-reg_gray_60">{{email['id']}}</p>
                          </div>
                          <div class="table-cell align-middle p-reg_12 w-reg_50_100">
                              <p class="text-xs_l_reg text-reg_black">{{email['status']}}</p>
                          </div>
                          <div class="table-cell align-middle p-reg_12 w-reg_20_100">
                              <button @click="showPopup(email['hash'])" class="text-xs_l_reg text-reg_blue">Открыть задачу</button>
                          </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="table w-full table-auto text-reg_red" style="margin: 10px 0px 10px 0px;">Отправления отсутствуют</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <collect-popup v-if="popups.collect.show" @hidePopup="hidePopupCollect()" :hash="popups.collect.hash" />

  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import ShipmentService from "@/common/services/shipment.service";
import EmailService from "@/common/services/email.service";
import printJS from "print-js";
import CollectPopup from "@/components/ui/popups/collect";
export default {
  name: "shipmentEmails",
  components: {SvgIcon, CollectPopup},
  data () {
      return {
        shipment_emails: [],
        loaded : false,
        popups : {
            collect : {
              show : false,
              hash : null
            },
        },
      }
  },
  created(){
      this.getShipment();
  },
  watch: {
    id : "getShipment"
  },
  props: {
      id : {}
  },
  methods : {
      async getShipment() {

        await ShipmentService.getItemEmails(this.id)
            .then((response) => {
                this.shipment_emails = response.data.shipment_emails;
            })
            .catch(() => {
              //todo
            })
            .finally(() => {
              this.loaded = true;
            });
      },
      hidePopup(){
          this.$root.$emit("shipmentEmailsHide");
      },
      hidePopupCollect(){
          this.popups.collect.show = false;
      },
      emailPdf(hash){
        EmailService.emailPdf(hash)
            .then((response) => {
              printJS({printable: response.data['pdf'], type: 'pdf', base64: true});
            })
            .catch((data) => {
              this.$nextTick(() => {
                this.$root.$emit("notificationShow", {title : "Ошибка формирования pdf файла", type : "error", "text" : data.data.message.errors_format.all});
              });
            })
      },
      showPopup(hash){
          this.popups.collect.show = true;
          this.popups.collect.hash = hash;
      },
  }
}
</script>

<style scoped>

</style>