<template>
    <div class="block-pink flex flex-row items-center my-reg_24 rounded border border-reg_red_25 bg-reg_red_10 py-reg_15 px-reg_15">
        <svg-icon name="icon__alert-circle" class="icon__alert-circle w-reg_18 h-reg_18 fill-reg_red" />
        <p class="pl-reg_10 text-sm_m_reg text-reg_black">{{message}}</p>
    </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";

export default {
  name: "notAllow",
  components: {
    SvgIcon
  },
  props: {
    message: {
        required: false,
        default: "У вас нет доступа к данному разделу"
    },
  }
}
</script>

<style scoped>

</style>