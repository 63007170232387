import Vue from "vue";
import Vuex from "vuex";

import user from "./user.module";
import email from "./email.module";
import order from "./order.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        email,
        order
    }
});