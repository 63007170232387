<template>
  <div class="container w-full bg-reg_white flex flex-row items-center justify-between py-reg_12 px-reg_40">
    <div class="header_left flex flex-row items-center">

      <router-link :to="{ name: 'index' }" class="header_logo-img bg-reg_blue rounded py-reg_14 px-reg_5 inline-flex">
        <svg-icon class="icon__logo-oma w-reg_30 h-reg_12 fill-reg_white" name="icon__logo-oma" />
      </router-link>

    </div>
    <div class="header_center w-reg_80_100 2xl:w-reg_85_100 flex-auto px-reg_24" v-click-outside="hideSearch" >
      <div class="search-form relative">

        <ui-input
            label=""
            type="text"
            name="street"
            :value="search.query"
            v-model="search.query"
            @focusIn="showSearch"
            class_input="search-form_input w-full py-reg_9 pr-reg_45 pl-reg_14 border border-reg_gray_75 rounded bg-reg_white text-sm_reg text-reg_gray_50"
            placeholder="Поиск в OMS"

        />

        <button class="search-form_btn absolute top-reg_12 right-reg_15 cursor-pointer" style="display: none;">
            <svg-icon class="icon__search w-reg_18 h-reg_18 fill-transparent stroke-reg_15 stroke-reg_gray_50" name="icon__search" type="button" />
        </button>

        <header-search :query="search.query" :show="search.show" @hideSearch="hideSearch" />

      </div>
    </div>
    <div class="header_right flex-auto flex flex-row justify-end">

      <div class="bg-reg_gray cursor-pointer hover:bg-reg_blue_10 rounded p-reg_11"  @click="showNotification">
          <svg-icon class="icon__bell w-reg_18 h-reg_18 fill-reg_black_75" name="icon__bell" />
      </div>

      <header-menu
          :is_active="is_active_header_menu"
          @toogleHeaderMenu="toogleHeaderMenu"
          @hideHeaderMenu="hideHeaderMenu"
      />

    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import HeaderMenu from "@/components/headermenu";
import HeaderSearch from "@/components/order/block/header-search";
import UiInput from "@/components/ui/form/elements/input";

export default {
  name: "VHeader",
  data: () => ({
      is_active_header_menu : false,
      search : {
          query : null,
          show : false
      }
  }),
  computed: {
    search_query() {
      return this.search.query;
    }
  },
  watch: {
      search_query : "showSearch",
  },
  components: {HeaderSearch, HeaderMenu, SvgIcon, UiInput},
  methods : {
      toogleHeaderMenu() {
          this.is_active_header_menu = !this.is_active_header_menu;
      },
      hideHeaderMenu() {
          this.is_active_header_menu = false;
      },
      hideSearch(){
          this.search.show = false;
      },
      showSearch(){
          this.search.show = true;
      },
      showNotification(){
          this.$root.$emit("notificationUiShow");
      }
  }
}
</script>
