<template>

  <button v-click-outside="hideHeaderMenu" @click="toogleHeaderMenu()" class="btn_drop-header relative px-reg_15 bg-reg_gray cursor-pointer hover:bg-reg_blue_10 rounded flex items-center ml-2">
    <span class="whitespace-nowrap text-reg_black text-sm_reg">{{getUserTitle}}</span>
    <ul :class="[is_active ? 'open' : '']" class="drop-header hidden shadow-reg_sm w-reg_200 bg-reg_white absolute top-full right-0 flex-col py-reg_8 rounded">
        <li :class="{active: this.$route.name === 'personal_statistics'}">
            <router-link :to="{ name: 'personal_statistics' }" class="w-full flex bg-reg_white py-reg_8 px-reg_15 text-reg_black text-sm_reg">
                Моя статистика
            </router-link>
        </li>
        <li :class="{active: this.$route.name === 'personal_change'}">
          <router-link :to="{ name: 'personal_change' }" class="w-full flex bg-reg_white py-reg_8 px-reg_15 text-reg_black text-sm_reg">
            Изменить пароль
          </router-link>
        </li>
        <li  :class="{active: this.$route.name === 'personal_other'}">
          <router-link :to="{ name: 'personal_other' }" class="w-full flex bg-reg_white py-reg_8 px-reg_15 text-reg_black text-sm_reg">
            Другие настройки
          </router-link>
        </li>
        <li class=" border-t border-reg_gray_75 pt-reg_8 mt-reg_8">
          <a @click="logout()" class="w-full flex bg-reg_white py-reg_8 px-reg_15 text-reg_black text-sm_reg">Выход</a>
        </li>
    </ul>

    <span class="pl-reg_8">
        <svg-icon class="icon__arrow-down w-reg_18 h-reg_18 fill-reg_gray_50" name="icon__arrow-down" />
    </span>

  </button>
</template>

<script>
import SvgIcon from "@/components/ui/svgicon";
import {LOGOUT} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
  name: "headerMenu",
  components: {SvgIcon},
  watch: {
      $route: 'hideHeaderMenu'
  },
  computed: {
    ...mapGetters(["getUserTitle"]),
  },
  props: {
      is_active: {
        type: Boolean,
        default : false
      },
  },
  methods : {
      toogleHeaderMenu() {
          this.$emit("toogleHeaderMenu");
      },
      hideHeaderMenu() {
          if(this.is_active)
            this.$emit("hideHeaderMenu");
      },
      async logout() {
          await this.$store.dispatch(LOGOUT)
          .then(() => {
              this.$router.push({ name: "auth" });
          })
      },
  }

}
</script>

<style scoped>

</style>