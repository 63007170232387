<template>
  <div style="border-bottom: 1px solid #ddd;padding-bottom: 15px;">
    <label v-if="label" class="relative text-sm_reg text-reg_black font-semibold">{{label}}</label>
    <ul class="mx-reg_12_m flex flex-row flex-wrap">
      <li :class="['w-1/'+column, 'p-reg_12']" v-for="(variant, key) in _variants" v-bind:key="key">
        <ui-checkbox
            :id="variant['id']"
            :name="name"
            :label="variant['value']"
            v-model="variant['checked']"
            @input="(name, value) => {change(key, value);}"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import UiCheckbox from "@/components/ui/form/elements/checkbox";
export default {
name: "ui-checkbox-group",
  components: {UiCheckbox},
  props: {
    name: {
      type: String,
      required: true
    },
    variants: {
      type: Array,
      required: true
    },
    label : {
      type: String
    },
    column : {
        type: Number,
        required: true
    }
  },
  computed: {
    _variants() {
      return this.variants.filter((variant) => variant['active'] === true);
    },
  },
  methods : {
    change(key, value){
      this.$emit("change", key, value);
    }

  }
}
</script>

<style scoped>

</style>